import {
  ImportContainer,
  ImportExcelPreview,
} from "../../../../../models/Import";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import CardActionArea from "@mui/material/CardActionArea";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import { Table as MuiTable } from "@mui/material";
import React, { Fragment, useState } from "react";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import useContainerSchema from "../../../../../hooks/container/useContainerSchema";
import useEntityProperties from "../../../../../hooks/container/useEntityProperties";
import PropertiesDialog from "./PropertiesDialog";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

type PreviewFlatProps = {
  data: ImportExcelPreview;
  duplicates: boolean;
  setDuplicates: React.Dispatch<React.SetStateAction<boolean>>;
};

const PreviewFlat = ({ data, duplicates, setDuplicates }: PreviewFlatProps) => {
  const { t } = useTranslation();
  const [openPropertiesDialog, setOpenPropertiesDialog] = useState(false);
  const [errorsExpanded, setErrorsExpanded] = useState(false);
  const [warningsExpanded, setWarningsExpanded] = useState(false);
  const [containersExpanded, setContainersExpanded] = useState(false);
  const [container, setContainer] = useState<ImportContainer>(
    new ImportContainer()
  );

  const [schema] = useContainerSchema(container.type);
  const { entityPropertyGroups: containerPropertyGroups } = useEntityProperties(
    schema,
    container
  );

  return (
    <Box mt={2} ml={1} mr={1}>
      <Stack direction={"column"} rowGap={2}>
        {!data.valid ? (
          <Stack direction={"row"} columnGap={0.5} alignItems={"center"}>
            <CancelOutlinedIcon color={"error"} />
            <Typography variant={"body1"}>
              {" "}
              {t("importContainer.modal.issuesDetected")}{" "}
            </Typography>
          </Stack>
        ) : (
          <Stack direction={"row"} columnGap={0.5} alignItems={"center"}>
            <CheckCircleOutlinedIcon color={"success"} />
            <Typography variant={"body1"}>
              {t("importContainer.modal.validFile")}
            </Typography>
          </Stack>
        )}
        <Divider />
        {!data.valid && (
          <Fragment>
            <Typography variant={"caption"}>
              {t("importContainer.modal.totalContainers")} :{" "}
              {data.containerTotal}
            </Typography>
            <Typography variant={"caption"}>
              {t("importContainer.modal.validContainers")} :{" "}
              {data.containerCount}
            </Typography>
          </Fragment>
        )}
        {data.warningCount > 0 && data.valid && (
          <Fragment>
            <Stack direction={"row"} alignItems={"flex-start"} columnGap={1}>
              <Typography variant={"caption"}>
                The file contains duplicate labels. To import them enable the “Duplicates” button.
              </Typography>
            </Stack>
            <FormControlLabel
              control={
                <Switch
                  checked={duplicates}
                  name={"Duplicates"}
                  onChange={() => setDuplicates(!duplicates)}
                  size={"small"}
                  sx={{ ml: 0.5 }}
                />
              }
              label={<Typography variant={"body2"}> Duplicates</Typography>}
              sx={{ mt: -1 }}
            />
          </Fragment>
        )}
        {data.errors.length > 0 && (
          <Stack gap={1}>
            <Card>
              <CardActionArea
                sx={{ px: 2, backgroundColor: "background.neutral" }}
                onClick={() => {
                  setErrorsExpanded(!errorsExpanded);
                }}
              >
                <Stack
                  py={0.5}
                  direction={"row"}
                  columnGap={0.5}
                  alignItems={"center"}
                >
                  <CancelOutlinedIcon color={"error"} fontSize={"small"} />
                  <Typography variant={"caption"} color={"error"} >
                    {t("importContainer.modal.errors")} ({data.errors.length})
                  </Typography>
                  <div style={{ flexGrow: 1 }}></div>
                  {errorsExpanded ? <ExpandLess /> : <ExpandMore />}
                </Stack>
              </CardActionArea>
            </Card>
            <Collapse in={errorsExpanded}>
              <Stack direction={"column"} rowGap={0.5} ml={2}>
                {data.errors.map((error, index) => (
                  <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                    <CircleIcon sx={{ fontSize: 6 }} />
                    <Typography key={index} variant={"body2"} color={"error"}>
                      {error}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Collapse>
          </Stack>
        )}
        {data.warnings.length > 0 && (
          <Stack gap={1}>
            <Card>
              <CardActionArea
                sx={{ px: 2, backgroundColor: "background.neutral" }}
                onClick={() => {
                  setWarningsExpanded(!warningsExpanded);
                }}
              >
                <Stack
                  py={0.5}
                  direction={"row"}
                  columnGap={0.5}
                  alignItems={"center"}
                >
                  <PriorityHighIcon
                    fontSize={"small"}
                    sx={{ color: "warning.main" }}
                  />
                  <Typography
                    variant={"caption"}
                    sx={{ color: "warning.main" }}
                  >
                    {t("importContainer.modal.warnings")} (
                    {data.warnings.length})
                  </Typography>
                  <div style={{ flexGrow: 1 }}></div>
                  {warningsExpanded ? <ExpandLess /> : <ExpandMore />}
                </Stack>
              </CardActionArea>
            </Card>
            <Collapse in={warningsExpanded}>
              <Stack direction={"column"} rowGap={0.5} ml={2}>
                {data.warnings.map((warning, index) => (
                  <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                    <CircleIcon sx={{ fontSize: 6 }} />
                    <Typography
                      key={index}
                      variant={"body2"}
                      sx={{ color: "warning.main" }}
                    >
                      {warning}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Collapse>
          </Stack>
        )}
        {data.containerCount > 0 && (
          <Stack gap={1}>
            <Card>
              <CardActionArea
                sx={{ px: 2, backgroundColor: "background.neutral" }}
                onClick={() => {
                  setContainersExpanded(!containersExpanded);
                }}
              >
                <Stack
                  py={0.5}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant={"caption"}>
                    {" "}
                    {t("importContainer.modal.containers")} (
                    {data.containerCount})
                  </Typography>
                  {containersExpanded ? <ExpandLess /> : <ExpandMore />}
                </Stack>
              </CardActionArea>
            </Card>
            <Collapse in={containersExpanded}>
              <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
                <MuiTable aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography color={"textSecondary"} variant={"body2"}>
                          {t("table.label")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Typography color={"textSecondary"} variant={"body2"}>
                          {t("table.type")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Typography color={"textSecondary"} variant={"body2"}>
                          {t("table.properties")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.containers.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                        id={row.type}
                      >
                        <TableCell component="th" scope="row">
                          <Typography variant={"body2"}>
                            {row.label}{" "}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography variant={"body2"}>{row.typeName} </Typography>
                        </TableCell>
                        <TableCell>
                          {row.properties.length > 0 ? (
                            <Tooltip
                              title={"Show properties"}
                              placement={"top"}
                              arrow
                            >
                              <IconButton
                                size={"small"}
                                onClick={() => {
                                  setContainer(row);
                                  setOpenPropertiesDialog(true);
                                }}
                              >
                                <PreviewOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <IconButton size={"small"} disabled>
                              <PreviewOutlinedIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </MuiTable>
              </TableContainer>
            </Collapse>
          </Stack>
        )}
      </Stack>

      <PropertiesDialog
        open={openPropertiesDialog}
        handleClose={() => setOpenPropertiesDialog(false)}
        containerPropertyGroups={containerPropertyGroups}
      />
    </Box>
  );
};

export default PreviewFlat;
