import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { MediaAsset, MediaAssetSize } from "../../../../../models/MediaAsset";
import DefaultCover from "../../../../../assets/images/default_image.png";
import DefaultLink from "../../../../../assets/images/default_link.png";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { VideoAssetType, VideoDataType } from "../../../../../extensions/guide/models/AssetType";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";

type Asset = { uuid: string, caption: string };

interface AssetItemProps {
  asset: Asset | string | VideoAssetType;
  captioned: boolean;
  onRemove: (uuid: string) => void;
  onCaptionChange: (uuid: string, caption: string) => void;
  onClick?: (uuid: string)=>void;
}

function getYoutubeThumbnail(url:string) {
  let thumbnailUrl = '';

  try {
    const videoId = url.split('https://www.youtube.com/embed/')[1];
    thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  } catch (e) {
    return DefaultLink;
  }

  return thumbnailUrl;
}

function AssetItem({ asset, captioned, onRemove, onCaptionChange, onClick }:AssetItemProps) {
  const { t } = useTranslation();
  // @ts-ignore
  const uuid = captioned ? asset.uuid || asset.data : asset;
  // @ts-ignore
  const caption = captioned ? asset.caption : "";
  // @ts-ignore
  const isVideoLink = asset?.source===VideoDataType.EXTERNAL
  // @ts-ignore
  const videoSrc = asset?.data ? (asset?.data.includes('youtube.com') && (!asset?.data.includes('channel'))) ? getYoutubeThumbnail(asset.data) : DefaultLink : DefaultLink

  const [hovered, setHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  if (!uuid) return null;

  return (
    <Stack
      key={uuid}
      justifyContent={"center"}
      alignItems={"center"}
      rowGap={1}
      position={"relative"}
      direction={"column"}
    >
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={()=>{
        if (isVideoLink && onClick) onClick(uuid);
      }}
      style={{
        cursor: isVideoLink?'pointer':'default'
      }}
      >
        <img
          alt={caption}
          src={isVideoLink?videoSrc:MediaAsset.getMediaUrl(uuid, MediaAssetSize.SM) ?? DefaultCover}
          style={{
            width: 180,
            height: 180,
            objectFit: "cover",
            borderRadius: 4
          }}

        />
        {isVideoLink&&<EditIcon
          sx={{
            fontSize: '3.5rem',
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            opacity: hovered ? 1 : 0,
            transition: 'opacity 0.3s ease',
            color: 'white'
        }}
        />}
      </div>

      <IconButton
        sx={{
          backgroundColor: "background.neutral",
          position: 'absolute',
          top: 0,
          right: 0,
          mr: -1.75,
          mt: -1.75,
          boxShadow: 1,
          ':hover': {
            backgroundColor: "background.neutral",
            boxShadow: 2,
          },
        }}
        size={"small"}
        onClick={() => onRemove(uuid)}
      >
        <CloseOutlined fontSize={"small"} />
      </IconButton>
      {captioned && <TextField
        size={'small'}
        variant={'outlined'}
        value={caption}
        onChange={(e) => onCaptionChange(uuid, e.target.value)}
        placeholder={t("assetpicker.caption").toString()}
      />}
    </Stack>
  );
}


export default AssetItem
