import * as React from "react";
import CoreLayout from "../../../../layouts/CoreLayout";
import { Link } from "react-router-dom";
import Navigation from "./Navigation";
import { Stack } from "@mui/material";
import useCollapsedLayout from "../../../../hooks/ui/useCollapsedLayout";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

type GuideLayoutProps = {
  children: React.ReactNode;
};

function GuideLayout({ children }: GuideLayoutProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  useCollapsedLayout()

  const renderLogo = () => {
    return (
      <Link to={`/guide`}>
        <Stack direction={"row"} columnGap={1} alignItems={"center"}>
          <img
            src={require(matches ? "../../../../assets/images/guide_logo_white_icon.png" : "../../../../assets/images/guide_logo_white.png")}
            style={{ height: 40, marginRight: "20px", minWidth: matches ? 40 : "auto" }}
            alt={"favicon"}
          />
        </Stack>
      </Link>
    );
  };

  return (
    <CoreLayout
      renderLogo={renderLogo}
      renderNavigation={(open) => <Navigation open={open} />}
      renderSearch={false}
      renderNotifications={true}
    >
      {children}
    </CoreLayout>
  );
}

export default GuideLayout;
