import { create } from "zustand";
import { API_URL } from "../utils/config";
import { fetchData } from "../utils/utils";
import { Notification, NotificationCount } from "../models/Notification";
import Paging from "../models/Pageable";
import { useEffect } from "react";

type NotificationStore = {
  notifications: Notification[];
  initialized: boolean;
  isLoading: boolean;
  getAll: () => void;
  unreadCount: number;
  getUnreadCount: () => void;
};

export const useNotificationStore = create<NotificationStore>((set) => ({
  notifications: [],
  initialized: false,
  isLoading: false,
  getAll: async () => {
    try {
      set({ isLoading: true })
      const result: Paging<Notification> = await fetchData(`${API_URL}/notifications?page=0&size=5&sort=createdAt,desc`)
      set({ notifications: result.content })
      set({ initialized: true })
    } catch (ex) { }
    set({ isLoading: false })
  },
  unreadCount: 0,
  getUnreadCount: async () => {
    try {
      const result: NotificationCount = await fetchData(`${API_URL}/notifications/count`)
      set({ unreadCount: result.unReadCount })
    } catch (ex) { }
  }
}));


export const useNotificationList = (): [Notification[], number, boolean] => {
  const store = useNotificationStore();
  const { notifications, initialized, isLoading, getAll, getUnreadCount, unreadCount } = store

  useEffect(() => {
    let time = setTimeout(() => {
      if (!initialized && !isLoading) {
        getAll();
        getUnreadCount()
      }
    }, 100)

    return () => {
      clearTimeout(time)
    }

  }, [getAll, getUnreadCount, initialized, isLoading]);

  return [notifications, unreadCount, isLoading];
};
