import {useCallback, useEffect, useMemo, useState} from "react";
import Schema from "../../models/Schema";
import { useGetInitialDefaultValue } from "../common/usePreviewDefaultValue";
import { PropertyHolder } from "../../models/Container";
import { UseType } from "../../models/UseType";
import { FlatProperty } from "../../models/FlatProperty";
import Property from "../../models/Property";
import {createMap, makeID} from "../../utils/utils";
import { PropertyType } from "../../models/PropertyType";
import { useVocabularyTermNamesStore } from "../properties/useVocabularyTermNames";
import Attribute from "../../models/Attribute";

export class EntityAttribute {
  attribute: Attribute
  value: any
  uuid: string = "";
  isEmpty: boolean = false
  constructor(attribute: Attribute) {
    this.attribute = attribute
  }
}
export class EntityProperty {
  uuid: string;
  schemaPropertyUuid?: string;
  value: any;
  property: FlatProperty;
  required: boolean = false;
  recommended: boolean = false;
  repeatable: boolean = false;
  isEmpty: boolean = false;
  attributes: EntityAttribute[] = []
  constructor(
    uuid: string,
    value: any,
    property: FlatProperty,
    schemaPropertyUuid?: string
  ) {
    this.uuid = uuid;
    this.value = value;
    this.property = property;
    this.schemaPropertyUuid = schemaPropertyUuid;
  }
}

export class EntitySchemaGroup {
  uuid: string
  name: string
  description: string
  properties: EntityProperty[]
  constructor(uuid: string, name: string, description: string, properties: EntityProperty[]) {
    this.uuid = uuid
    this.name = name
    this.description = description
    this.properties = properties
  }
}

type UseEntityPropertiesOutput = {
  entityPropertyGroups: EntitySchemaGroup[],
  handleChange: (uuid: string, value: any) => void,
  handleChangeAttribute: (propertyUUID: string, uuid: string, value: any) => void,
  handleSetProperties: () => void,
  handleAddRepeatableProperty: (entityProperty: EntityProperty) => void,
  handleRemoveRepeatableProperty: (uuid: string) => void,
  vocabularyTermUUIDs: string[]
  dirtyUUIDs: string[]
  hasNewItems: boolean
}

function isEmpty(value: any) {
  return (
      value === undefined ||
      value === null ||
      value === "" ||
      value?.length === 0
  )
}

const flatten = (groups: EntitySchemaGroup[]) => {
  const properties: EntityProperty[] = []
  groups.forEach(group => {
    group.properties.forEach(property => {
      properties.push(property)
    })
  })
  return properties
}

const useEntityProperties = (schema: Schema, entity?: PropertyHolder, requestDependencies = false): UseEntityPropertiesOutput => {

  const [entityPropertyGroups, setEntityPropertyGroups] = useState<EntitySchemaGroup[]>([])
  const [groupsCopy, setGroupsCopy] = useState<EntitySchemaGroup[]>([])
  const [vocabularyTermUUIDs, setVocabularyTermUUIDs] = useState<string[]>([])

  const { getTermNames } = useVocabularyTermNamesStore()

  const getDefaultValueInitialState = useGetInitialDefaultValue()

  const hasNewItems = useMemo(() => {

    const editing = flatten(entityPropertyGroups)
    const withEditing = editing.find(property => {
      if (property.uuid.includes("fakeID")) {
        return true
      }
      property.attributes.forEach(attribute => {
        return attribute.uuid.includes("fakeID")
      })

      return false
    })

    return Boolean(withEditing)
  }, [entityPropertyGroups])

  const dirtyUUIDs = useMemo(() => {

    const dirtyPropertyUUIDs: string[] = []
    // const dirtyAttributeUUIDs: string[] = []

    const initial = flatten(groupsCopy)
    const editing = flatten(entityPropertyGroups)
    const editingMap = createMap(editing, "uuid")

    initial.forEach(property => {
      const propertyMatch = editingMap.get(property.uuid)
      if (!propertyMatch) return
      if (JSON.stringify(property.value) !== JSON.stringify(propertyMatch.value)) {
        dirtyPropertyUUIDs.push(property.uuid)
      }
      property.attributes.forEach(attribute => {
        const attrMatch = propertyMatch.attributes.find(x => x.uuid === attribute.uuid)
        if (!attrMatch) return
        if (JSON.stringify(attribute.value) !== JSON.stringify(attrMatch.value)) {
          // dirtyAttributeUUIDs.push(attrMatch.uuid)
          dirtyPropertyUUIDs.push(property.uuid)
        }
      })
    })

    return dirtyPropertyUUIDs
  }, [entityPropertyGroups, groupsCopy])


  const handleChange = useCallback((uuid: string, value: any) => {
    setEntityPropertyGroups(prevGroups => {
      return prevGroups.map(group => {
        return {
          ...group,
          properties: group.properties.map(property => {
            if (property.uuid === uuid) {
              return {
                ...property,
                value: value
              }
            }
            return property
          })
        }
      })
    })
  }, [])

  const handleChangeAttribute = useCallback((propertyUUID: string, uuid: string, value: any) => {
    setEntityPropertyGroups(prevGroups => {
      return prevGroups.map(group => {
        return {
          ...group,
          properties: group.properties.map(property => {
            if (property.uuid === propertyUUID) {
              return {
                ...property,
                attributes: property.attributes.map(attribute => {
                  if (attribute.uuid === uuid) {
                    return {
                      ...attribute,
                      value
                    }
                  }
                  return attribute
                })
              }
            }
            return property
          })
        }
      })
    })
  }, [])

  const handleAddRepeatableProperty = useCallback((entityProperty: EntityProperty) => {
    const newProperty = {...entityProperty, value: undefined}
    setEntityPropertyGroups(prevGroups => {
      return prevGroups.map(group => {
        const index = group.properties.findIndex(x => x.uuid === entityProperty.uuid)
        const properties = [...group.properties]
        properties.splice(index, 0, {...newProperty, uuid: `fakeID_${makeID()}`})
        return {
          ...group,
          properties
        }
      })
    })
  }, [])

  const handleRemoveRepeatableProperty = useCallback((uuid: string) => {
    setEntityPropertyGroups(prevGroups => {
      return prevGroups.map(group => {
        return {
          ...group,
          properties: [
            ...group.properties.filter(x => x.uuid !== uuid)
          ]
        }
      })
    })
  }, [])

  const handleSetProperties = useCallback(() => {
    const addedVocabularyTermUUIDS: string[] = []

    const schemaGroups = schema.schemaGroups
      .sort((a, b) => a.position - b.position)
      .map(group => {

        const properties: EntityProperty[] = []

        group.properties
          .sort((a, b) => a.position - b.position)
          .forEach(schemaProperty => {
            const flatProperty = Property.toFlatProperty(schemaProperty.property)
            flatProperty.uuid = schemaProperty.property.uuid
            const defaultValue = getDefaultValueInitialState(flatProperty.propertyType, flatProperty.multipleValues)
            const entityProperties = entity?.properties.filter(x => x.propertyUuid === schemaProperty.property.uuid) ?? []

            // Handle properties exist on schema but not on container or datastream
            if (entityProperties.length === 0) {
              const uuid = `fakeID_${makeID()}_skip`
              const entityProperty = new EntityProperty(uuid, defaultValue, flatProperty, schemaProperty.uuid)
              entityProperty.required = schemaProperty.use === UseType.REQUIRED
              entityProperty.recommended = schemaProperty.use === UseType.RECOMMENDED
              entityProperty.repeatable = schemaProperty.repeatable
              entityProperty.isEmpty = true
              entityProperty.attributes = schemaProperty.property.attributes.map(attribute => {
                const entityAttribute = new EntityAttribute(attribute)
                entityAttribute.isEmpty = true
                entityAttribute.value = undefined
                entityAttribute.uuid = `fakeID_${makeID()}_skip`
                return entityAttribute
              })
              properties.push(entityProperty)
            }

            entityProperties.forEach(containerProperty => {
              const value = containerProperty.value ?? defaultValue
              const uuid = containerProperty.uuid ?? `fakeID_${makeID()}`
              const entityProperty = new EntityProperty(uuid, value, flatProperty, schemaProperty.uuid)
              entityProperty.required = schemaProperty.use === UseType.REQUIRED
              entityProperty.recommended = schemaProperty.use === UseType.RECOMMENDED
              entityProperty.repeatable = schemaProperty.repeatable
              entityProperty.isEmpty = isEmpty(entityProperty.value)
              entityProperty.attributes = schemaProperty.property.attributes.map(attribute => {
                const containerAttribute = containerProperty.attributes.find(x => x.attributeUuid === attribute.uuid)
                const entityAttribute = new EntityAttribute(attribute)
                entityAttribute.isEmpty = isEmpty(containerAttribute?.value)
                entityAttribute.value = containerAttribute?.value
                entityAttribute.uuid = containerAttribute?.uuid ?? `fakeID_${makeID()}_skip`
                return entityAttribute
              })
              properties.push(entityProperty)

              // Handle stored term UUIDs, set all stored ids to array to get the names from the api
              if (entityProperty.property.propertyType === PropertyType.VOCABULARY && containerProperty?.value) {
                if (Array.isArray(containerProperty.value)) {
                  containerProperty.value.forEach((uuid: string) => {
                    if (!addedVocabularyTermUUIDS.includes(uuid)) {
                      addedVocabularyTermUUIDS.push(uuid)
                    }
                  })
                }
              }
            })
        })

        return {
          ...group,
          properties
        }
      })

    setEntityPropertyGroups(schemaGroups)
    setGroupsCopy(schemaGroups)
    setVocabularyTermUUIDs(addedVocabularyTermUUIDS)
  }, [entity?.properties, getDefaultValueInitialState, schema.schemaGroups])

  useEffect(() => {

    handleSetProperties()

  }, [handleSetProperties])

  // Get vocabulary term names
  useEffect(() => {
    if (!requestDependencies || vocabularyTermUUIDs.length === 0) return
    getTermNames(vocabularyTermUUIDs)
  }, [getTermNames, requestDependencies, vocabularyTermUUIDs])

  return {
    handleRemoveRepeatableProperty,
    entityPropertyGroups,
    handleAddRepeatableProperty,
    handleChange,
    handleSetProperties,
    vocabularyTermUUIDs,
    handleChangeAttribute,
    dirtyUUIDs,
    hasNewItems
  }
}

export default useEntityProperties
