import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import Header from "./Header";
import ErrorBoundary from "../../components/ErrorBoundary";
import FileManagerWidget from "../../components/FileManagerWidget";
import {useUIStore} from "../../store/uiStore";

const drawerWidth: number = 250;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",

      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

type DefaultLayoutProps = {
  children: React.ReactNode;
  renderNavigation?: (open: boolean) => JSX.Element
  renderLogo: () => JSX.Element
  renderSearch: boolean;
  renderNotifications: boolean;
};

function CoreLayout({ children, renderNavigation, renderLogo, renderNotifications, renderSearch }: DefaultLayoutProps) {

  const { drawerOpen, toggleLayout } = useUIStore()

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="absolute" open={drawerOpen} style={{ overflow: "hidden" }}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleLayout}
            sx={{
              mr:2,
            }}
          >
            <MenuIcon fontSize={"small"} />
          </IconButton>
          {renderLogo()}
          <Header renderNotifications={renderNotifications} renderSearch={renderSearch} />
        </Toolbar>
      </AppBar>
      {Boolean(renderNavigation)&&<Drawer variant="permanent" open={drawerOpen}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        />
        {renderNavigation && renderNavigation(drawerOpen)}
      </Drawer>}
      <Box
        component="main"
        id={"main-container"}
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <FileManagerWidget />
        <Container maxWidth={false} sx={{ mt: 8.5 }}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </Container>
      </Box>
    </Box>
  );
}

export default CoreLayout;
