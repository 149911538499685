import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import DatastreamCard from "../../components/DatastreamCard";
import React, { useCallback, useMemo } from "react";
import ShareLink from "../../models/ShareLink";
import { Datastream } from "../../models/Datastream";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {API_URL} from "../../utils/config";
import {downloadFile} from "../../utils/utils";
import {StyledBreadcrumb} from "../../components/Common/BreadCrumb";
import { useLocation, useNavigate } from "react-router-dom";
import useQuery, { updateQueryStringParameter } from "../../hooks/query/useQuery";
import { RepoxType } from "../../models/RepoxType";
import PreviewDialog from "../../components/PreviewDialog";


type DatastreamViewProps = {
  datastream: Datastream;
  link: ShareLink;
};

const DatastreamView = ({
  datastream,
  link,

}: DatastreamViewProps) => {
  const { pathname, search } = useLocation();
  const searchParams = useQuery();
  const navigate = useNavigate();

  const previewUuid = searchParams.get("datastream");
  const isShortcut = Boolean(searchParams.get("isShortcut"));

  const handleDownload = useCallback(() => {
    const url = `${API_URL}/datastreams/${datastream.uuid}/download`;
    const filename = datastream.bitstreamFile?.filename;
    downloadFile(url, filename);
  }, [ datastream]);

  const handlePreview = useCallback(() => {
    let newSearch;
    if (datastream.repoxType === RepoxType.REPOX_LINK) {
      const newSearch1 = updateQueryStringParameter(
        search,
        "datastream",
        datastream.uuid
      );
      newSearch = updateQueryStringParameter(newSearch1, "isShortcut", "true");
    } else {
      newSearch = updateQueryStringParameter(search, "datastream", datastream.uuid);
    }
    navigate(`${pathname}${newSearch}`);
  },[datastream, navigate, pathname, search]);

  const openPreview = useMemo(() => {
    return !!previewUuid;
  }, [previewUuid]);

  return (
    <Container maxWidth={"sm"} sx={{ pb: 10 }}>
      <Card sx={{ border: "none", mt: 4, boxShadow: 12 }}>
        <CardContent>
          <Stack direction={"column"} rowGap={1}>
            <Stack direction={"row"} alignItems={"center"}>
              <StyledBreadcrumb label={`${datastream.label}`} />
              <div style={{ flexGrow: 1 }}></div>
            </Stack>
            {link.expiredAt && (
              <Stack flexDirection={"row"} alignItems={"center"} columnGap={1}>
                <PriorityHighIcon color={"primary"} fontSize={"small"} />
                <Typography variant={"caption"} color={"primary"}>
                  {" "}
                  link expires at: {link.expiredAt.split(" ")[0]}
                </Typography>
              </Stack>
            )}
          </Stack>

          <Stack flexDirection={"column"}>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  <DatastreamCard
                    datastream={datastream}
                    onDoubleClick={handlePreview}
                  />
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </CardContent>
      </Card>
      <PreviewDialog
        open={openPreview}
        handleClose={() => {
          navigate(`${pathname}`);
        }}
        handleDownload={handleDownload}
        previewUuid={previewUuid}
        isShortcut={isShortcut}
        shortcuts={[datastream]}
        toNavigate={false}
      />
    </Container>
  );
};

export default DatastreamView;
