import React, { Fragment, useEffect, useState } from "react";
import MenuButton from "../../../components/MenuButton/MenuButton";
import DomainAddOutlinedIcon from "@mui/icons-material/DomainAddOutlined";
import {Badge, ClickAwayListener, Popper, Stack} from "@mui/material";
import RepoxAppsMenu from "./RepoxAppsMenu";
import { alpha } from "@mui/material/styles";
import { GlobalSearchPopper } from "../../../components/GlobalSearch/GlobalSearchPopper";
import GlobalSearchField from "../../../components/GlobalSearch/GlobalSearchField";
import { common } from "@mui/material/colors";
import {Link, useNavigate} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import PaletteSelector from "../../../components/PaletteSelector";
import AccountPopover from "../../../components/UserAccount/AccountPopover";
import useQuery from "../../../hooks/query/useQuery";
import LanguagePicker from "../../../components/LanguagePicker";
import { flags } from "../../../flags";
import SettingsPopper from "../../../components/GlobalSearch/SettingsPopper";
import ReservedUUID from "../../../models/ReservedUUID";
import { postData } from "../../../utils/utils";
import { API_URL } from "../../../utils/config";
import Container from "../../../models/Container";
import { FullTextSearchDatastream } from "../../../models/Datastream";
import NotificationIcon from "../../../components/NotificationIcon";
import SavedSearchOutlinedIcon from "@mui/icons-material/SavedSearchOutlined";
import ViewSavedSearchDialog from "../../../pages/Search/ViewSavedSearchDialog/ViewSavedSearchDialog";
import {Chat} from "@mui/icons-material";
import {useIAMPermission} from "../../../store/userStore";
import IAMPermission from "../../../models/IAMPermission";

type DefaultHeaderProps = {
  renderSearch: boolean;
  renderNotifications: boolean;
};

const btnStyles = {
  "&:hover": {
    backgroundColor: alpha(common.white, 0.25),
  },
  borderRadius: "8px",
};

const iconStyles = {
  color: "common.white",
  width: 24,
};

const Header = ({ renderNotifications, renderSearch }: DefaultHeaderProps) => {
  const searchParams = useQuery();
  const textQuery = searchParams.get("text") ?? "";
  const [searchResultContainers, setSearchResultContainers] = useState<
    Container[]
  >([]);
  const [searchResultDatastreams, setSearchResultDatastreams] = useState<
    FullTextSearchDatastream[]
  >([]);

  const [value, setValue] = useState<string>(textQuery);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElSettings, setAnchorElSettings] =
    React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const [openTheme, setOpenTheme] = useState(false);
  const [openViewSavedSearch, setOpenViewSavedSearch] = useState(false);

  useEffect(() => {
    setValue(textQuery);
  }, [textQuery]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSettings = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSettings(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElSettings(null);
  };
  const iam = useIAMPermission();
  const open = Boolean(anchorEl);
  const openSettings = Boolean(anchorElSettings);
  const id = open ? "simple-popper" : undefined;
  const idSettings = openSettings ? "simple-popper" : undefined;

  useEffect(() => {
    if (!value) {
      return;
    }

    const payload = {
      location: ReservedUUID.MyContentRoot,
      value: value,
      labelSensitivity: "CASE_INSENSITIVE",
      owner: "ANYONE",
      type: "ALL",
      filetype: [],
      isTrashed: false,
      publishStatus: "ALL",
      propertyValueFilters: [],
      fulltextSearch: false,
    };

    let timeout = setTimeout(() => {
      postData(`${API_URL}/searchEM`, payload)
        .then((data) => {
          setSearchResultContainers(data.containers.content);
          setSearchResultDatastreams(data.datastreams.content);
        })
        .catch((ex) => console.log(ex));
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  const routeChange = (parameters: string) => {
    navigate(`/store/search${parameters}`);
  };

  return (
    <Fragment>
      {renderSearch && (
        <Fragment>
          <ClickAwayListener onClickAway={handleClose}>
            <div>
              <GlobalSearchField
                value={value}
                setValue={setValue}
                handleClick={handleClick}
                handleClickSettings={handleClickSettings}
                handleClose={handleClose}
                onEnter={routeChange}
              />
              <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
                sx={{ zIndex: 10000 }}
              >
                <GlobalSearchPopper
                  value={value}
                  containers={searchResultContainers}
                  datastreams={searchResultDatastreams}
                  handleClose={handleClose}
                  onFilterClick={routeChange}
                />
              </Popper>
              <Popper
                id={idSettings}
                open={openSettings}
                anchorEl={anchorElSettings}
                placement="bottom-end"
                sx={{ zIndex: 10000 }}
              >
                <SettingsPopper
                  open={openSettings}
                  handleClose={() => setAnchorElSettings(null)}
                />
              </Popper>
            </div>
          </ClickAwayListener>
          <IconButton
            onClick={() => setOpenViewSavedSearch(true)}
            sx={btnStyles}
          >
            <SavedSearchOutlinedIcon sx={iconStyles} />
          </IconButton>
          {iam.has(IAMPermission.ai_conversation_view) && (
            <Badge badgeContent={"New"} color="success">
              <Link to={"/store/chat-gpt"}>
                <IconButton sx={btnStyles}>
                  <Chat sx={iconStyles} />
                </IconButton>
              </Link>
            </Badge>
          )}
        </Fragment>
      )}
      <div style={{ flexGrow: 1 }}></div>
      <Stack flexDirection={"row"} columnGap={1} alignItems={"center"}>
        {flags.hasTranslation && <LanguagePicker />}
        <IconButton
          sx={btnStyles}
          id="demo-positioned-button"
          aria-controls={open ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={() => {
            setOpenTheme(true);
          }}
        >
          <ColorLensIcon sx={{ color: "common.white", width: 24 }} />
        </IconButton>
        {flags.hasRepoxApps && (
          <MenuButton
            menu={RepoxAppsMenu()}
            icon={<DomainAddOutlinedIcon sx={iconStyles} />}
            sx={btnStyles}
            menuMargin={8}
          />
        )}

        {renderNotifications && <NotificationIcon />}

        <AccountPopover />
      </Stack>
      <PaletteSelector
        open={openTheme}
        handleClose={() => setOpenTheme(false)}
      />
      <ViewSavedSearchDialog
        open={openViewSavedSearch}
        handleClose={() => setOpenViewSavedSearch(false)}
      />
    </Fragment>
  );
};

export default Header;
