import Dialog from "../../../../../components/Dialog";
import { EntitySchemaGroup } from "../../../../../hooks/container/useEntityProperties";
import PropertyTable from "../../PrintPreviewDialog/PrintPreview/PropertyTable";
import * as React from "react";
import {useTranslation} from "react-i18next";

type PropertiesDialogProps = {
  open: boolean;
  handleClose: () => void;
  containerPropertyGroups: EntitySchemaGroup[];
};

function PropertiesDialog({
  containerPropertyGroups,
  open,
  handleClose,
}: PropertiesDialogProps) {
  const {t} = useTranslation();
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t("table.properties").toString()}
      primaryAction={handleClose}
      primaryActionTitle={t("buttons.ok").toString()}
      maxWidth={"sm"}
      fullWidth
    >
      <PropertyTable containerPropertyGroups={containerPropertyGroups} />
    </Dialog>
  );
}

export default PropertiesDialog;
