import Dialog from "../../../../components/Dialog";
import * as React from "react";
import { useFormik } from "formik";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { exportFile, postData } from "../../../../utils/utils";
import { API_URL } from "../../../../utils/config";
import { Fragment, useEffect, useMemo, useState } from "react";
import { ImportFormats } from "../ImportDialog/ImportDialog";
import { useExportFormElements } from "./form-elements";
import useFormElements from "../../../../hooks/form/useFormElements";
import ContainerReport from "../../../../models/ContainerReport";
import {useTranslation} from "react-i18next";

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  containerUUID: string;
};

export const enum ExportFormats {
  ARCHIVE = "ARCHIVE",
  EXCEL = "EXCEL",
}

const initialState = {
  format: "",
  metaData: false,
  datastreams: false,
  datastreamNameConvention: "",
};

function ExportDialog({ open, handleClose, containerUUID }: DialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [containerReport, setContainerReport] = useState<ContainerReport>(
    new ContainerReport()
  );
  const [activeStep, setActiveStep] = React.useState(0);
  const [formatType, setFormatType] = React.useState("female");
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (!open) return;
    setFormatType("");
  }, [open]);

  const handleExcelExport = async () => {
    try {
      const res = await postData(
        `${API_URL}/containers/${containerUUID}/reports`,
        ""
      );
      setContainerReport(res);
      handleNext();
    } catch (ex: any) {
      console.log(ex);
    }
  };

  const disabledExport = useMemo(() => {
    return !(containerReport.containerCount > 0);
  }, [containerReport]);

  const {
    values,
    setValues,
    handleChange,
    isSubmitting,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { setSubmitting }) => {
      if (formatType === ExportFormats.ARCHIVE) {
        if (!values.format) return;

        const payload = {
          container: containerUUID,
          datastream: "",
          exportMetadata: values.metaData,
          exportDatastreams: values.datastreams,
          archiveFormat: values.format,
          datastreamNameConvention: values.datastreams
            ? values.datastreamNameConvention
            : "LABEL",
        };

        setSubmitting(true);
        try {
          await exportFile(`${API_URL}/export_archive`, payload);
          setActiveStep(0);
          handleClose();
          resetForm();
        } catch (ex) {
          // @ts-ignore
          enqueueSnackbar(ex.message, { variant: "error" });
        }
        setSubmitting(false);
      } else {
        setSubmitting(true);
        try {
          await exportFile(
            `${API_URL}/containers/${containerUUID}/export_xlsx`
          );
          setActiveStep(0);
          handleClose();
          resetForm();
        } catch (ex) {
          // @ts-ignore
          enqueueSnackbar(ex.message, { variant: "error" });
        }
        setSubmitting(false);
      }
    },
  });

  const formElements = useExportFormElements(values);

  const form = useFormElements({
    formElements,
    values,
    handleChange,
    inProgress: isSubmitting,
    variant: "standard",
    setValues,
    smallSize: true,
  });

  const handleChangeFormatType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormatType((event.target as HTMLInputElement).value);
  };

  const onClose = () => {
    setActiveStep(0);
    handleClose();
    resetForm();
  };

  return (
    <Dialog
      open={open}
      handleClose={onClose}
      title={t("exportContainer.modal.title").toString()}
      maxWidth={"sm"}
      fullWidth
      inProgress={isSubmitting}
    >
      <Stack sx={{ width: "100%" }}>
        <form onSubmit={handleSubmit}>
          <Stepper activeStep={activeStep}>
            <Step>
              <StepLabel
                optional={
                  <Typography variant="body2">{t("exportContainer.modal.selectExportFormats").toString()}</Typography>
                }
              >
                <Typography variant="caption">{t("exportContainer.modal.exportFormats").toString()}</Typography>
              </StepLabel>
            </Step>
            <Step>
              <StepLabel
                optional={
                  <Typography variant="body2">
                    {" "}
                    {formatType === ImportFormats.ARCHIVE
                      ? "Repox Archive"
                      : ""}
                    {formatType === ImportFormats.EXCEL ? "Excel" : ""}
                  </Typography>
                }
              >
                <Typography variant="caption">
                  {t("exportContainer.modal.configureExportDetails").toString()}
                </Typography>
              </StepLabel>
            </Step>
          </Stepper>
          {activeStep === 0 && (
            <Fragment>
              <Stack mt={2} ml={2} height={200}>
                <FormControl>
                  <RadioGroup
                    value={formatType}
                    onChange={handleChangeFormatType}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="ARCHIVE"
                      control={<Radio size={"small"} color="primary" />}
                      label="Repox Archive"
                    />
                    <FormControlLabel
                      value="EXCEL"
                      control={<Radio size={"small"} color="primary" />}
                      label="Excel"
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                mt={4}
              >
                <div style={{ flexGrow: 1 }}></div>
                <Button
                  variant="contained"
                  onClick={
                    formatType === ImportFormats.ARCHIVE
                      ? handleNext
                      : handleExcelExport
                  }
                  disabled={!formatType}
                >
                  <Typography variant="caption">{t("buttons.next")}</Typography>
                </Button>
              </Stack>
            </Fragment>
          )}
          {activeStep === 1 && (
            <Fragment>
              <Stack mt={2} ml={2} height={200}>
                {formatType === ExportFormats.ARCHIVE && form}
                {formatType === ExportFormats.EXCEL && disabledExport && (
                  <Typography variant={"body2"} color={"error"} sx={{ mt: 2 }}>
                    ! This container cannot be exported in excel format because
                    it does not include any containers{" "}
                  </Typography>
                )}
              </Stack>
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                mt={4}
              >
                <Button onClick={handleBack}>
                  <Typography variant="caption">{t("buttons.back")}</Typography>
                </Button>
                <div style={{ flexGrow: 1 }}></div>
                <Button
                  variant="contained"
                  type={"submit"}
                  disabled={
                    formatType === ExportFormats.EXCEL && disabledExport
                  }
                >
                  <Typography variant="caption">{t("buttons.export")}</Typography>
                </Button>
              </Stack>
            </Fragment>
          )}
        </form>
      </Stack>
    </Dialog>
  );
}
export default ExportDialog;
