import * as React from "react";
import CoreLayout from "../../../../layouts/CoreLayout";
import { Link } from "react-router-dom";
import Navigation from "./Navigation";
import { Stack } from "@mui/material";
import useCollapsedLayout from "../../../../hooks/ui/useCollapsedLayout";

type SurveyLayoutProps = {
  children: React.ReactNode;
};

function SurveyLayout({ children }: SurveyLayoutProps) {

  useCollapsedLayout()

  const renderLogo = () => {
    return (
      <Link to={`/guide`}>
        <Stack direction={"row"} columnGap={1} alignItems={"center"}>
          <img
            src={require("../../../../assets/images/surveys/vector_surveys.png")}
            style={{ width: 25}}
            alt={"favicon"}
          />
          <img
            src={require("../../../../assets/images/surveys/surveys_logo_white.png")}
            style={{ marginRight: "20px" }}
            alt={"favicon"}
          />
        </Stack>
      </Link>
    );
  };

  return (
    <CoreLayout
      renderLogo={renderLogo}
      renderNavigation={(open) => <Navigation open={open} />}
      renderSearch={false}
      renderNotifications={true}
    >
      {children}
    </CoreLayout>
  );
}

export default SurveyLayout;
