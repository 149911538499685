import Typography from "@mui/material/Typography";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { Table as MuiTable } from "@mui/material";
import React from "react";
import { EntitySchemaGroup } from "../../../../../hooks/container/useEntityProperties";
import PropertyValue from "./PropertyValue";
import {useTranslation} from "react-i18next";

type TableProps = {
  containerPropertyGroups: EntitySchemaGroup[];
};
function PropertyTable({ containerPropertyGroups }: TableProps) {
  const {t} = useTranslation();
  return (
    <Stack gap={4}>
      {containerPropertyGroups.map((group) => (
        <Stack gap={1}>
          <Typography variant={"body2"}>
            {" "}
            {t("printContainer.modal.propertyGroup")}: {group.name}
          </Typography>
          <TableContainer component={Paper} sx={{borderRadius:0}}>
            <MuiTable aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{borderColor:"#808080"}}>
                    <Typography color={"primary"} variant={"body2"}>
                      {t("table.label")}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{borderColor:"#808080"}}>
                    {" "}
                    <Typography color={"primary"} variant={"body2"}>
                      {t("table.value")}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {group.properties.map((containerProperty) => (
                  <TableRow
                    key={containerProperty.uuid}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row" sx={{borderColor:"#808080"}}>
                      <Typography variant={"body2"}>
                        {containerProperty.property.name}{" "}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{borderColor:"#808080"}}>
                      <PropertyValue entityProperty={containerProperty} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </MuiTable>
          </TableContainer>
        </Stack>
      ))}
    </Stack>
  );
}

export default PropertyTable;
