import TagsType from "./TagsType";
import {PropertyHolder} from "./Container";

export class DatastreamNew {
  label: string = "";
  type: string = "";
  properties: Details[] = [];
}

export class BitstreamFile {
  uuid: string = "";
  viewUrl: string = "";
  filename: string = "";
  filesize: number = 0;
  mimeType: string = "";
  createdAt: string = "";
  thumbnails : Thumbnails = new Thumbnails();
}

class Thumbnails {
  largeUrl: string = "";
  mediumUrl: string = "";
  smallUrl: string = "";
}

export class Datastream extends PropertyHolder {
  uuid: string = "";
  label: string = "";
  type: Type = {
    uuid: "",
    name: "",
  };
  typeName: string = "";
  parent: string = "";
  createdAt: string = "";
  createdBy: CreatedBy = {};
  updatedAt: string = "";
  updatedBy: UpdatedBy = {};
  publishedAt: string = "";
  publishedBy: PublishedBy = {};
  textExtracted: boolean = false;
  textChunked: boolean = false;
  repoxType: string = "";
  objectType: string = "";
  objectId: string = "";
  tags: TagsType[] = [];
  ancestorPath: Path[] = [];
  bitstreamFile = new BitstreamFile();
  setAsCover = false
}

type Type = {
  uuid: string;
  name: string;
};

type CreatedBy = {
  uuid?: string;
  firstname?: string;
  lastname?: string;
  username?: string;
  email?: string;
};

type UpdatedBy = {
  uuid?: string;
  firstname?: string;
  lastname?: string;
  username?: string;
  email?: string;
};

type PublishedBy = {
  uuid?: string;
  firstname?: string;
  lastname?: string;
  username?: string;
  email?: string;
};

class Details {
  uuid: string = "";
  propertyUuid: string = "";
  value: {} = {};
}

class Path {
  uuid: string = "";
  label: string = "";
  publishedAt: string = "";
}

export class FlatDatastreamNew {
  label: string = "";
  type: string = "";
}
export class FullTextSearchDatastream extends Datastream {
  fullTextResult : FullTextResult ={};
}

export class DatastreamText {
  content: string = "There is no text";
  createdAt: string = "";
}

export type FullTextResult  = {
  score?: number,
  highlightedFields?: string[],
  highlightedSize?: number,
}
