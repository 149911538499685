enum ComponentType {
  ITEM = "ITEM",
  LIST = "LIST",
  ROUTE = "ROUTE",
  PAGE = "PAGE",
  SETTINGS = "SETTINGS",
  KEYPAD = "KEYPAD"
}

export default ComponentType
