import { Button, Stack, Typography } from "@mui/material";
import * as React from "react";
import { repoxApps } from "../../../constants/repox-apps";
import { Link } from "react-router-dom";
import {useIAMPermission} from "../../../store/userStore";

export default function RepoxAppsMenu() {
  const iam = useIAMPermission()
  return (
    <Stack direction={"row"} gap={1} ml={1} mr={1}>
      {repoxApps
        .filter(x => iam.hasSome(x.permissions))
        .map((app, index) => (
        <Button color={"primary"} target={"_blank"} component={Link} to={app.path} disabled={app.disabled} key={index}>
          <Stack direction={"column"} gap={1} alignItems={"center"}>
            {app.icon}
            <Typography variant={"caption"}>{app.name}</Typography>
          </Stack>
        </Button>
      ))}
    </Stack>
  );
}
