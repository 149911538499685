import { ImportArchivePreview } from "../../../../../models/Import";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import CardActionArea from "@mui/material/CardActionArea";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CircleIcon from "@mui/icons-material/Circle";
import {useTranslation} from "react-i18next";

type PreviewArchiveProps = {
  data: ImportArchivePreview;
};

const PreviewArchive = ({ data }: PreviewArchiveProps) => {
  const { t } = useTranslation();
  const [errorsExpanded, setErrorsExpanded] = useState(false);
  const [warningsExpanded, setWarningsExpanded] = useState(false);

  return (
    <Box mt={2} ml={1} mr={1}>
      <Stack direction={"column"} rowGap={2}>
        {!data.valid ? (
          <Stack direction={"row"} columnGap={0.5} alignItems={"center"}>
            <CancelOutlinedIcon color={"error"} />
            <Typography variant={"body1"}>{t("importContainer.modal.issuesDetected")} </Typography>
          </Stack>
        ) : (
          <Stack direction={"row"} columnGap={0.5} alignItems={"center"}>
            <CheckCircleOutlinedIcon color={"success"} />
            <Typography variant={"body1"}>{t("importContainer.modal.validFile")} </Typography>
          </Stack>
        )}
        <Divider />
        <Typography variant={"caption"}>
          {t("importContainer.modal.totalContainers")} : {data.containerTotal}
        </Typography>
        {!data.valid && (
          <Typography variant={"caption"}>
            {t("importContainer.modal.validContainers")} : {data.containerCount}
          </Typography>
        )}
        <Typography variant={"caption"}>
          {t("importContainer.modal.totalDatastreams")} : {data.datastreamTotal}
        </Typography>
        {!data.valid && (
          <Typography variant={"caption"}>
            {t("importContainer.modal.validDatastreams")} : {data.datastreamCount}
          </Typography>
        )}
        {data.errors.length > 0 && (
          <Stack gap={1}>
            <Card>
              <CardActionArea
                sx={{ px: 2, backgroundColor: "background.neutral" }}
                onClick={() => {
                  setErrorsExpanded(!errorsExpanded);
                }}
              >
                <Stack
                  py={0.5}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant={"caption"}>
                    {t("importContainer.modal.errors")} ({data.errors.length})
                  </Typography>
                  {errorsExpanded ? <ExpandLess /> : <ExpandMore />}
                </Stack>
              </CardActionArea>
            </Card>
            <Collapse in={errorsExpanded}>
              <Stack direction={"column"} rowGap={0.5} ml={2}>
                {data.errors.map((error, index) => (
                  <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                    <CircleIcon sx={{ fontSize: 6 }} />
                    <Typography key={index} variant={"body2"} color={"error"}>
                      {error}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Collapse>
          </Stack>
        )}
        {data.warnings.length > 0 && (
          <Stack gap={1}>
            <Card>
              <CardActionArea
                sx={{ px: 2, backgroundColor: "background.neutral" }}
                onClick={() => {
                  setWarningsExpanded(!warningsExpanded);
                }}
              >
                <Stack
                  py={0.5}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant={"caption"}>
                    {t("importContainer.modal.warnings")} ({data.warnings.length})
                  </Typography>
                  {warningsExpanded ? <ExpandLess /> : <ExpandMore />}
                </Stack>
              </CardActionArea>
            </Card>
            <Collapse in={warningsExpanded}>
              <Stack direction={"column"} rowGap={0.5} ml={2}>
                {data.warnings.map((warning, index) => (
                  <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                    <CircleIcon sx={{ fontSize: 6 }} />
                    <Typography
                      key={index}
                      variant={"body2"}
                      sx={{ color: "warning.main" }}
                    >
                      {warning}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Collapse>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default PreviewArchive;
