import React from "react";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import "../../App.css";
import FolderIcon from "@mui/icons-material/Folder";
import PublicIcon from "@mui/icons-material/Public";
import Box from "@mui/material/Box";
import { ContainerObjectType } from "../../models/Container";

type ContainerCardProps = {
  container: ContainerObjectType;
  isActive?: boolean;
  handleOnClick?: React.MouseEventHandler<HTMLElement>;
  handleOnDoubleClick?: React.MouseEventHandler<HTMLElement>;
  onContextMenu?: React.MouseEventHandler<HTMLElement>;
  isSelected?: boolean;
  onSelect?: React.MouseEventHandler<HTMLElement>;
  selectable?: boolean;
};

const ContainerCard = React.memo((props: ContainerCardProps) => {

  const {
    container,
    handleOnClick,
    onContextMenu,
    isActive,
    handleOnDoubleClick,
    isSelected,
    onSelect,
    selectable,
  } = props;

  return (
    <Card
      variant={"elevation"}
      onClick={selectable && onSelect ? onSelect : handleOnClick}
      id={container.uuid}
      onContextMenu={onContextMenu}
      onDoubleClick={handleOnDoubleClick}
      sx={{
        border: "1px solid",
        borderColor: isActive ? "primary.main" : "divider",
      }}
    >
      <CardContent style={{ padding: 0 }}>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          // columnGap={2}
          sx={{ p: 2 }}
        >
          {container.color && (
            <Box
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                mr: 1,
                mt: 1,
                width: 40,
                height: 6,
                borderRadius: 2,
                backgroundColor: container.color,
              }}
            />
          )}

          {container.publishedAt && (
            <Tooltip title={"Published"}>
              <PublicIcon
                fontSize={"small"}
                color={"primary"}
                sx={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  mr: 1,
                  mb: 1,
                }}
              />
            </Tooltip>
          )}

          <Collapse
            in={selectable}
            orientation={"horizontal"}
          >
            <Checkbox
              size={"small"}
              checked={isSelected}
              id={container.uuid}
              //onChange={onSelect}
              sx={{ mr: 2 }}
            />
          </Collapse>

          {container.coverFile && container.coverFile.thumbnails.mediumUrl ? (
            <Avatar src={container.coverFile.thumbnails.mediumUrl} />
          ) : (
            <IconButton
              size={"medium"}
              sx={{
                backgroundColor: "secondary.main",
                "&:hover": {
                  backgroundColor: "secondary.main",
                },
              }}
            >
              <FolderIcon />
            </IconButton>
          )}

          <Stack
            ml={2}
            flexDirection={"column"}
            alignItems={"flex-start"}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              display: "block",
            }}
          >
            <Typography noWrap variant={"body1"}>
              {container.label}
            </Typography>
            <Typography noWrap color={"primary"} variant={"body2"}>
              {container.type}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
})

export default ContainerCard;
