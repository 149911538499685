import Dialog from "../../../../components/Dialog";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Collapse from "@mui/material/Collapse";
import { useSnackbar } from "notistack";
import { API_URL } from "../../../../utils/config";
import { Fragment, useEffect, useState } from "react";
import FileUpload from "../../../../components/FileUpload/file-upload.component";
import axios from "axios";
import {
  ImportArchivePreview,
  ImportExcelPreview,
} from "../../../../models/Import";
import PreviewFlat from "./Previews/PreviewFlat";
import PreviewArchive from "./Previews/PreviewArchive";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useFormik } from "formik";
import { useAdvancedOptionElements } from "./form-elements";
import useFormElements from "../../../../hooks/form/useFormElements";
import { useTranslation } from "react-i18next";
import { downloadMultipleFiles } from "../../../../utils/utils";

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  containerUUID: string;
  containerTypeUUID: string;
  onUpdate: () => void;
};

export const enum ImportFormats {
  ARCHIVE = "ARCHIVE",
  EXCEL = "EXCEL",
}

const initialValues = {
  containerType: "",
  datastreamType: "",
};

function ImportDialog({
  open,
  handleClose,
  containerUUID,
  containerTypeUUID,
  onUpdate,
}: DialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [formatType, setFormatType] = useState("");
  const [excelPreviewData, setExcelPreviewData] = useState<ImportExcelPreview>(
    new ImportExcelPreview()
  );
  const [optionsExpanded, setOptionsExpanded] = useState(false);
  const [archivePreviewData, setArchivePreviewData] =
    useState<ImportArchivePreview>(new ImportArchivePreview());
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [duplicates, setDuplicates] = useState(false);

  const updateUploadedFiles = (files: File[]) => {
    setNewFiles(files);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (!open) return;
    setFormatType("");
  }, [open]);

  const handleChangeFormatType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormatType((event.target as HTMLInputElement).value);
  };

  const handleDownloadTemplate = () => {
    downloadMultipleFiles(
      `${API_URL}/cm/container_types/${containerTypeUUID}/template_xlsx`,
      { }
    );
  };

  const onClose = () => {
    setActiveStep(0);
    setNewFiles([]);
    setValues({ datastreamType: "", containerType: "" });
    setOptionsExpanded(false);
    handleClose();
  };

  const {
    values,
    handleChange,
    setValues,
    isSubmitting,
    handleSubmit,
    setSubmitting,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const url =
        formatType === ImportFormats.EXCEL
          ? `${API_URL}/import_xlsx/submit?to=${containerUUID}`
          : `${API_URL}/import_archive/submit?to=${containerUUID}`;

      const request =
        formatType === ImportFormats.EXCEL
          ? {
              containers: excelPreviewData.containers,
              labelDeduplication: duplicates,
            }
          : {
              containerType: values.containerType,
              datastreamType: values.datastreamType,
            };

      const formData = new FormData();
      formData.append("file", newFiles[0]);
      formData.append(
        "request",
        new Blob([JSON.stringify(request)], { type: "application/json" })
      );

      setSubmitting(true);
      try {
        await axios.postForm(url, formData);
        onClose();
        onUpdate();
      } catch (ex: any) {
        enqueueSnackbar(ex.message, { variant: "error" });
      }
      setSubmitting(false);
    },
  });

  const elements = useAdvancedOptionElements();

  const form = useFormElements({
    formElements: elements,
    values: values,
    handleChange,
    setValues: setValues,
  });

  const handlePreviewExcel = async () => {
    const url = `${API_URL}/import_xlsx/preview?to=${containerUUID}`;

    const formData = new FormData();
    formData.append("file", newFiles[0]);
    setSubmitting(true);
    try {
      const res = await axios.postForm(url, formData);
      setExcelPreviewData(res.data);
      handleNext();
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }
    setSubmitting(false);
  };

  const handlePreviewArchive = async () => {
    const url = `${API_URL}/import_archive/preview?to=${containerUUID}`;
    const request = {
      containerType: values.containerType,
      datastreamType: values.datastreamType,
    };

    const formData = new FormData();
    formData.append("file", newFiles[0]);
    formData.append(
      "request",
      new Blob([JSON.stringify(request)], { type: "application/json" })
    );
    setSubmitting(true);
    try {
      const res = await axios.postForm(url, formData);
      setArchivePreviewData(res.data);
      handleNext();
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }
    setSubmitting(false);
  };

  return (
    <Dialog
      open={open}
      handleClose={onClose}
      title={t("importContainer.modal.title").toString()}
      maxWidth={"md"}
      fullWidth
      inProgress={isSubmitting}
    >
      <Stack sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel
              optional={
                <Typography variant="body2">
                  {t("importContainer.modal.selectImportFormats").toString()}
                </Typography>
              }
            >
              <Typography variant="caption">
                {t("importContainer.modal.importFormats").toString()}
              </Typography>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel
              optional={
                <Typography variant="body2">
                  {formatType === ImportFormats.ARCHIVE ? "Repox Archive" : ""}
                  {formatType === ImportFormats.EXCEL ? "Excel" : ""}
                </Typography>
              }
            >
              <Typography variant="caption">
                {t("importContainer.modal.upload").toString()}
              </Typography>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Typography variant="caption">
                {t("importContainer.modal.previewImport").toString()}
              </Typography>
            </StepLabel>
          </Step>
        </Stepper>
        {activeStep === 0 && (
          <Fragment>
            <Stack mt={2} ml={2} height={150}>
              <FormControl>
                <RadioGroup
                  value={formatType}
                  onChange={handleChangeFormatType}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="ARCHIVE"
                    control={<Radio size={"small"} color="primary" />}
                    label="Repox Archive"
                  />
                  <FormControlLabel
                    value="EXCEL"
                    control={<Radio size={"small"} color="primary" />}
                    label="Excel"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mt={4}
            >
              <div style={{ flexGrow: 1 }}></div>
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={!formatType}
              >
                <Typography variant="caption">{t("buttons.next")}</Typography>
              </Button>
            </Stack>
          </Fragment>
        )}
        {activeStep === 1 && (
          <Fragment>
            <Stack mt={2} ml={2} mr={2} height={510}>
              <FileUpload
                accept={
                  formatType === ImportFormats.ARCHIVE
                    ? ".zip,.tar.gz"
                    : ".xlsx"
                }
                updateFilesCb={updateUploadedFiles}
                message={t("uploadComponent.uploadMsg.regular").toString()}
                acceptMessage={
                  formatType === ImportFormats.ARCHIVE
                    ? ".zip, .tar.gz"
                    : ".xlsx"
                }
              />

              {formatType === ImportFormats.ARCHIVE && (
                <Fragment>
                  <Card>
                    <CardActionArea
                      sx={{ px: 2, backgroundColor: "background.neutral" }}
                      onClick={() => {
                        setOptionsExpanded(!optionsExpanded);
                      }}
                    >
                      <Stack
                        py={1}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography variant={"caption"}>
                          {t("importContainer.modal.advancedOptions")}
                        </Typography>
                        {optionsExpanded ? <ExpandLess /> : <ExpandMore />}
                      </Stack>
                    </CardActionArea>
                  </Card>
                  <Collapse in={optionsExpanded}>
                    <Stack m={1}>{form}</Stack>
                  </Collapse>
                </Fragment>
              )}
              {formatType === ImportFormats.EXCEL && (
                <Stack py={1} direction={"row"} alignItems={"flex-start"}>
                  <Button onClick={handleDownloadTemplate} sx={{ textTransform: "none" }}>
                    <Typography variant="caption">
                      {t("myContent.actions.template")}
                    </Typography>
                  </Button>
                </Stack>
              )}
            </Stack>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mt={4}
            >
              <Button onClick={handleBack}>
                <Typography variant="caption">{t("buttons.back")}</Typography>
              </Button>
              <div style={{ flexGrow: 1 }}></div>
              <Button
                variant="contained"
                disabled={!newFiles.length}
                onClick={
                  formatType === ImportFormats.EXCEL
                    ? handlePreviewExcel
                    : handlePreviewArchive
                }
              >
                <Typography variant="caption">{t("buttons.next")}</Typography>
              </Button>
            </Stack>
          </Fragment>
        )}
        {activeStep === 2 && (
          <form onSubmit={handleSubmit}>
            <Stack mt={2} ml={2} mr={2} height={510} sx={{ overflow: "auto" }}>
              {formatType === ImportFormats.EXCEL ? (
                <PreviewFlat
                  data={excelPreviewData}
                  duplicates={duplicates}
                  setDuplicates={setDuplicates}
                />
              ) : (
                <PreviewArchive data={archivePreviewData} />
              )}
            </Stack>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mt={4}
            >
              <Button onClick={handleBack}>
                <Typography variant="caption">{t("buttons.back")}</Typography>
              </Button>
              <div style={{ flexGrow: 1 }}></div>
              <Button
                variant="contained"
                type={"submit"}
                disabled={
                  formatType === ImportFormats.EXCEL && !excelPreviewData.valid
                    ? true
                    : formatType === ImportFormats.ARCHIVE &&
                      !archivePreviewData.valid
                }
              >
                <Typography variant="caption">{t("buttons.import")}</Typography>
              </Button>
            </Stack>
          </form>
        )}
      </Stack>
    </Dialog>
  );
}
export default ImportDialog;
