import { useParams } from "react-router-dom";
import { ContainerExtended } from "../../models/Container";
import { API_URL } from "../../utils/config";
import { useFetch } from "../../hooks/common/useFetch";
import React, {
  Fragment,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ObjectType } from "../../models/ObjectType";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import logo from "../../assets/images/store_logo_blue.png";
import Password from "./Password";
import ShareLinkInfo from "../../models/ShareLinkInfo";
import { fetchData, getTenantId } from "../../utils/utils";
import ShareLink from "../../models/ShareLink";
import { Datastream } from "../../models/Datastream";
import ContainerView from "./ContainerView";
import DatastreamView from "./DatastreamView";
import { ShareLinkStatus } from "../../models/ShareLinkStatus";
import {useSnackbar} from "notistack";

const SharedView = () => {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedContainer, setSelectedContainer] = useState<ContainerExtended>(new ContainerExtended());
  const [selectedContainerUUID, setSelectedContainerUUID] = useState("");

  const [linkInfo] = useFetch(
    params.id ? `${API_URL}/share/${params.id}/info?t=${getTenantId()}` : null,
    new ShareLinkInfo()
  );

  const [valid, setValid] = useState<boolean>(false);

  const link_url = useMemo(() => {
    return !(linkInfo.status === ShareLinkStatus.VALID || valid)
      ? null
      : `${API_URL}/share/${params.id}?t=${getTenantId()}`;
  }, [linkInfo, valid, params.id]);

  const [link] = useFetch(link_url, new ShareLink());

  const [container, loadingContainer] = useFetch<ContainerExtended>(
    link.objectId && link.objectType === ObjectType.CONTAINER
      ? `${API_URL}/containers/${link.objectId}`
      : null,
    new ContainerExtended()
  );

  const [datastream, loadingDatastream] = useFetch<Datastream>(
    link.objectId && link.objectType === ObjectType.DATASTREAM
      ? `${API_URL}/datastreams/${link.objectId}`
      : null,
    new Datastream()
  );

  /* initialize selected container */
  useEffect(() => {
    setSelectedContainer(container)
  }, [container]);

  /* GET selected container */
  useEffect(() => {
    if (!selectedContainerUUID) return;

    fetchData(`${API_URL}/containers/${selectedContainerUUID}`)
      .then((data) => {
        setSelectedContainer(data);
      })
      .catch((ex) => {
        enqueueSnackbar(ex.message);
      });
  }, [selectedContainerUUID, enqueueSnackbar]);

  const handleClickContainerCard = (event: React.MouseEvent<HTMLElement>) => {
    setSelectedContainerUUID(event.currentTarget.id);
  };

  /* set breadCrumbs */
  const breadCrumbs = useMemo(() => {
    return selectedContainer.ancestorPath
      .map((path) => {
        return {
          uuid: path.uuid,
          name: path.label,
        };
      })
      .reverse();
  }, [selectedContainer]);

  return (
    <Container maxWidth={false} sx={{ pt: 2 }}>
      <img src={logo} alt={"logo"}   style={{ height: 40, marginLeft: "20px", width: "auto" }} />
      {linkInfo &&
        (linkInfo.status === ShareLinkStatus.EXPIRED ||
          linkInfo.status === ShareLinkStatus.RESTRICTED ||
          linkInfo.status === ShareLinkStatus.CANCELED) && (
          <Stack direction={"column"} alignItems={"center"} sx={{ mt: 4 }}>
            {" "}
            <Typography variant={"h5"} color={"primary"}>
              {linkInfo.message}
            </Typography>
          </Stack>
        )}
      {linkInfo &&
        linkInfo.status === ShareLinkStatus.PASSWORD_PROTECTED &&
        !valid && <Password setValid={setValid} />}
      {linkInfo && (linkInfo.status === ShareLinkStatus.VALID || valid) && (
        <Fragment>
          {link.objectType === ObjectType.CONTAINER && (
            <Fragment>
              {loadingContainer ? (
                <Stack alignItems={"center"} justifyContent={"center"}>
                  <CircularProgress />
                </Stack>
              ) : (
                <ContainerView
                  container={container}
                  selectedContainer={selectedContainer}
                  link={link}
                  breadCrumbs={breadCrumbs}
                  setSelectedContainerUUID={setSelectedContainerUUID}
                  handleClickContainerCard={handleClickContainerCard}
                />
              )}
            </Fragment>
          )}
          {link.objectType === ObjectType.DATASTREAM && (
            <Fragment>
              {loadingDatastream ? (
                <Stack alignItems={"center"} justifyContent={"center"}>
                  <CircularProgress />
                </Stack>
              ) : (
                <DatastreamView
                  datastream={datastream}
                  link={link}
                />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Container>
  );
};

export default SharedView;
