import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import AudioFileOutlinedIcon from "@mui/icons-material/AudioFileOutlined";
import FolderZipOutlinedIcon from "@mui/icons-material/FolderZipOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";

export const DatastreamMimetypeOptions = [
  {
    icon: InsertDriveFileOutlinedIcon,
    title: "search.type.datastreams",
    id: "Datastreams",
    query: "DATASTREAM",
    keyValue: "type",
    color: "primary.main",
  },
  {
    icon: ImageOutlinedIcon,
    title: "search.type.image",
    id: "Image",
    keyValue: "type",
    query: "image/jpeg,image/png,image/tiff,image/gif",
    color: "#FF6C40",
  },
  {
    icon: PictureAsPdfOutlinedIcon,
    title: "search.type.pdf",
    id: "PDF",
    keyValue: "type",
    query: "application/pdf",
    color: "#33ccff",
  },
  {
    icon: NoteAltOutlinedIcon,
    title: "search.type.text",
    id: "Text",
    keyValue: "type",
    query: "text/plain,application/rtf",
    color: "#33cc33",
  },
  {
    icon: TextSnippetOutlinedIcon,
    title: "search.type.word",
    id: "Word",
    keyValue: "type",
    query:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword",
    color: "#ff4da6",
  },
  {
    icon: FilePresentOutlinedIcon,
    title: "search.type.presentation",
    id: "Presentation",
    keyValue: "type",
    query:
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    color: "#d24dff",
  },
  {
    icon: SummarizeOutlinedIcon,
    title: "search.type.excel",
    id: "Excel",
    keyValue: "type",
    query:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
    color: "#ffd11a",
  },
  {
    icon: FileOpenOutlinedIcon,
    title: "search.type.openOffice",
    id: "Open Office",
    keyValue: "type",
    query:
      "application/vnd.oasis.opendocument.text,application/vnd.oasis.opendocument.presentation,application/vnd.oasis.opendocument.spreadsheet",
    color: "#adad85",
  },
  {
    icon: OndemandVideoOutlinedIcon,
    title: "search.type.video",
    id: "Video",
    keyValue: "type",
    query: "video/mp4",
    color: "#669900",
  },
  {
    icon: AudioFileOutlinedIcon,
    title: "search.type.audio",
    id: "Audio",
    keyValue: "type",
    query: "audio/mpeg",
    color: "#77773c",
  },
  {
    icon: ViewInArIcon,
    title: "search.type.3d",
    id: "3D",
    keyValue: "type",
    query: "application/octet-stream",
    color: "#b33c00",
  },
  {
    icon: FolderZipOutlinedIcon,
    title: "search.type.archive",
    id: "Archive",
    keyValue: "type",
    query: "application/zip,application/gzip",
    color: "#ff6699",
  },
  {
    icon: FilePresentOutlinedIcon,
    title: "Shortcut",
    id: "Shortcut",
    keyValue: "type",
    query: "",
    color: "primary.main",
  },
  {
    icon: ExploreOutlinedIcon,
    title: "Guide",
    id: "Guide",
    keyValue: "type",
    query: "",
    color: "#129F9B",
  },
];
