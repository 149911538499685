import { useEffect, useState } from "react";
import { fetchData } from "../../utils/utils";
import { API_URL } from "../../utils/config";
import { useProfileStore } from "../../store/userStore";
import { Profile } from "../../models/User";

export const useTokenValidation = () => {
  const { setProfile } = useProfileStore();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchData(API_URL + `/my/profile`)
      .then((res: Profile) => {
        setProfile(res);
        setLoading(false);
      })
      .catch(() => {
        localStorage.removeItem("token");
        setLoading(false);
      });
  }, [setProfile]);

  return isLoading;
};
