import {UseType} from "./UseType";
import Property from "./Property";
import SchemaGroup from "./SchemaGroup";

export class ShortSchema {
  uuid: string = "";
  name: string = "";
}

class Schema extends ShortSchema {
  description: string = "";
  totalSchemaPropertyCount: number = 0;
  rootElement: string = "";
  namespaces: string = "";
  schemaGroups: SchemaGroup[] = []
  managed: boolean = true;
}

export class SchemaProperty {
  uuid: string = ""
  use: UseType = UseType.OPTIONAL
  repeatable: boolean = false
  indexable: boolean = false
  publishable: boolean = false
  slug: string = ""
  minOccurs: number = 0
  maxOccurs: number = 0
  position: number = 0
  schemaGroupUuid: string = ""
  property: Property

  constructor(property: Property) {
    this.property = property
  }

  static createPayload(schemaProperty: SchemaProperty) {
    return {
      ...schemaProperty,
      propertyUuid: schemaProperty.property.uuid,
      property: null,
    }
  }
}


export default Schema
