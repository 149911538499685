import Dialog from "../../Dialog";
import * as React from "react";
import {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import MediaGrid from "./MediaGrid";
import MediaUpload from "./MediaUpload";
import useMediaLibrary from "../../../hooks/common/mediaLibrary";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Stack from "@mui/material/Stack";
import SearchField from "../SearchField";
import PaginationControl from "../../../pages/Content/Containers/Common/PaginationControl";
import {
  FileExtensions,
  FileType,
  ImageDimensions,
} from "../../../models/Import";
import { MediaAssetType } from "../../../models/MediaAsset";
import Button from "@mui/material/Button";
import UploadIcon from "@mui/icons-material/Upload";
import { useTranslation } from "react-i18next";
import {useSnackbar} from "notistack";

export enum MediaFormat {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  FILES = "FILES",
  MODEL_3D = "MODEL_3D"
}

type Props = {
  open: boolean;
  handleClose: () => void;
  onSelectConfirm?: (assets: string[]) => void;
  singleSelect?: boolean;
  filetype?: FileType;
  imageDimensionsReq?: ImageDimensions;
  allowedFormats?: [MediaFormat]
};

const FORMATS = [
  { id: MediaFormat.IMAGE, name: "Image"},
  { id: MediaFormat.VIDEO, name: "Video"},
  { id: MediaFormat.AUDIO, name: "Audio"},
  { id: MediaFormat.FILES, name: "Files"},
  { id: MediaFormat.MODEL_3D, name: "Model 3D"},
]


function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function MediaLibraryDialog(props: Props) {
  const {
    open,
    handleClose,
    onSelectConfirm,
    singleSelect,
    filetype,
    imageDimensionsReq ,
    allowedFormats
  } = props;

  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const [query, setQuery] = useState("");
  const [openUpload, setOpenUpload] = useState(false)
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar()

  const {
    uploading,
    handleDrop,
    assetResult,
    loading,
    handleChange,
    handleUploadFile,
    form,
    newFiles,
    handleFetchAssets,
    page,
    setPage,
    format,
    setFormat,
    progressStatus
  } = useMediaLibrary(open, query, MediaAssetType.ASSET, openUpload);

  const filteredFormats = useMemo(() => {
    return allowedFormats
      ? FORMATS.filter(x => allowedFormats.includes(x.id))
      : FORMATS
  }, [allowedFormats])

  useEffect(() => {
    if (open) {
      if (filteredFormats.length < FORMATS.length && filteredFormats.length > 0) {
        setFormat(filteredFormats[0].id)
      } else {
        setFormat("")
      }
    }
  }, [filteredFormats, open, setFormat])

  const handleConfirmSelection = () => {
    onSelectConfirm && onSelectConfirm(selectedAssets);
  };

  const primaryActionTitle = useMemo(() => {
    if (Boolean(onSelectConfirm))
      return selectedAssets.length > 1
        ? `Import (${selectedAssets.length})`
        : `OK`;
    return undefined;
  }, [onSelectConfirm, selectedAssets.length]);

  const enabledPrimaryAction = useMemo(() => {
      return newFiles.length > 0 && form.label !== "" && !uploading;
  }, [form.label, newFiles.length, uploading]);

  const handleSelect = useCallback((uuid: string) => {
      setSelectedAssets((prevState) => {

        if (prevState.includes(uuid)) {
          return prevState.filter((x) => x !== uuid);
        }

        if (singleSelect) {
          return [uuid];
        }

        return [...prevState, uuid];
      });
    },
    [singleSelect]
  );

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={open}
        handleClose={handleClose}
        primaryActionTitle={primaryActionTitle}
        primaryAction={
          onSelectConfirm
            ? handleConfirmSelection
            : undefined
        }
        title={"Media Library"}
        secondaryAction={handleClose}
        secondaryActionTitle={"Cancel"}
        inProgress={loading}
      >
        <Tabs
          value={format}
          onChange={(e, newValue) => setFormat(newValue)}
          indicatorColor="primary"
          aria-label="full width tabs example"
        >
          {filteredFormats.length === FORMATS.length && <Tab label={"All"} value={""} {...a11yProps(-1)}/>}
          {filteredFormats.map((format, index) => (
            <Tab key={format.id} value={format.id} label={format.name} {...a11yProps(index)} />
          ))}
        </Tabs>
        <Stack rowGap={2} mt={2} sx={{ minHeight: 460 }}>
          <Stack columnGap={2} direction={"row"}>
            <div style={{ flex: 1 }}>
              <SearchField value={query} setValue={setQuery} />
            </div>
            <Button
              onClick={() => setOpenUpload(true)}
              size={"small"}
              startIcon={<UploadIcon/>}
              variant={"contained"}>
              Upload
            </Button>
          </Stack>
          <MediaGrid
            result={assetResult}
            loading={loading}
            handleAdd={() => setOpenUpload(true)}
            checkable={Boolean(onSelectConfirm)}
            selectedAssets={selectedAssets}
            handleSelect={handleSelect}
            handleRefresh={handleFetchAssets}
          />
          {assetResult.totalPages > 1 && (
            <Stack mx={"auto"}>
              <PaginationControl
                disabled={loading}
                count={assetResult.totalPages}
                page={page + 1}
                onPageChange={(page) => setPage(page)}
              />
            </Stack>
          )}
        </Stack>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        primaryActionTitle={"Upload"}
        title={t("medialibrary.upload.file").toString()}
        disablePrimaryAction={!enabledPrimaryAction}
        primaryAction={async () => {

          try {
            await handleUploadFile()
            setOpenUpload(false)
          } catch (ex: any) {
            const message = ex?.response?.data?.message ?? ex.message
            enqueueSnackbar(message, { variant: "error" });
          }

        }}
        open={openUpload} handleClose={() => setOpenUpload(false)}>
        <MediaUpload
          uploading={uploading}
          setFiles={handleDrop}
          form={form}
          handleChange={handleChange}
          accept={filetype && FileExtensions[filetype]}
          imageDimensionsReq={imageDimensionsReq}
          progressStatus={progressStatus}
        />
      </Dialog>
    </Fragment>
  );
}

export default MediaLibraryDialog;
