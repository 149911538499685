import { MultiSelector } from "./Selector";
import React, { ChangeEventHandler, useEffect, useState } from "react";
import { Option } from "../../hooks/form/useFormElements";
import { fetchData, sortBy } from "../../utils/utils";

type DatasourceSelectorProps = {
  label?: string;
  value: string[];
  onChange: ChangeEventHandler<HTMLSelectElement>;
  name: string;
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  recommended?: boolean;
  helperText?: string;
  error?: boolean;
  url?: string;
  labelProp?: string;
  valueProp?: string;
  createOption?: false | (() => void);
};

function AsyncFlatSelect(props: DatasourceSelectorProps) {
  const {
    createOption,
    label,
    value,
    onChange,
    name,
    disabled,
    fullWidth,
    required,
    recommended,
    error,
    helperText,
    url,
    labelProp = "label",
    valueProp = "uuid",
  } = props;

  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (!url) return;
    fetchData(url)
      .then((res) =>
        setOptions(
          res
            .map((item: any) => ({
              ...item,
              id: item[valueProp],
              name: item[labelProp],
            }))
            .sort((a: any, b: any) => sortBy(a, b, "name"))
        )
      )
      .catch((ex) => console.log(ex));
  }, [labelProp, url, valueProp]);

  return (
    <MultiSelector
      options={options}
      fullWidth={fullWidth}
      label={label}
      value={value}
      // @ts-ignore
      onChange={onChange}
      name={name}
      disabled={disabled}
      required={required}
      recommended={recommended}
      helperText={helperText}
      error={error}
      createOption={createOption}
    />
  );
}

export default AsyncFlatSelect;
