import { useMemo } from "react";
import {
  ElementType,
  FormElement,
} from "../../../../hooks/form/useFormElements";

type values = {
  datastreamNameConvention: string;
  metaData: boolean;
  format: string;
  datastreams: boolean;
};
export const useExportFormElements = (values: values): FormElement[] => {
  return useMemo(() => {
    if (values.datastreams) {
      return [
        {
          id: "format",
          label: "formElement.archiveFormat",
          type: ElementType.select,
          hasNone: true,
          isRequired: true,
          options: [
            { id: "ZIP", name: "ZIP" },
            { id: "TAR_GZIP", name: "TAR_GZIP" },
          ],
          columns: 6,
        },
        {
          id: "metaData",
          label: "formElement.exportMetadata",
          type: ElementType.boolean,
          size: "small",
          columns: 12,
        },
        {
          id: "datastreams",
          label: "formElement.exportDatastreams",
          type: ElementType.boolean,
          columns: 12,
        },
        {
          id: "datastreamNameConvention",
          label: "formElement.datastreamsOrigin",
          type: ElementType.select,
          hasNone: true,
          isRequired: true,
          options: [
            { id: "ORIGINAL_FILENAME", name: "ORIGINAL_FILENAME" },
            { id: "LABEL", name: "LABEL" },
          ],
          columns: 6,
        },
      ];
    }
    return [
      {
        id: "format",
        label: "formElement.archiveFormat",
        type: ElementType.select,
        hasNone: true,
        isRequired: true,
        options: [
          { id: "ZIP", name: "ZIP" },
          { id: "TAR_GZIP", name: "TAR_GZIP" },
        ],
        columns: 6,
      },

      {
        id: "metaData",
        label: "formElement.exportMetadata",
        type: ElementType.boolean,
        size: "small",
        columns: 12,
      },
      {
        id: "datastreams",
        label: "formElement.exportDatastreams",
        type: ElementType.boolean,
        columns: 12,
      },
    ];
  }, [values]);
};
