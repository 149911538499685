import { EntityProperty } from "../../../../../hooks/container/useEntityProperties";
import { PropertyType } from "../../../../../models/PropertyType";
import {
  DataSource,
  SelectOption,
  TextTransform,
} from "../../../../../models/DataOptions";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useVocabularyTermNamesStore } from "../../../../../hooks/properties/useVocabularyTermNames";
import { API_URL } from "../../../../../utils/config";
import { fetchData } from "../../../../../utils/utils";

type PropertyValueProps = {
  entityProperty: EntityProperty;
};
type SearchResult = {
  uuid: string;
  label: string;
  parent: string;
};

const PropertyValue = ({ entityProperty }: PropertyValueProps) => {
  const { termMap, loading } = useVocabularyTermNamesStore();
  const [selected, setSelected] = useState<SearchResult | null>(null);

  const selectPropertyLabel = useMemo(() => {
    if (entityProperty.property.propertyType === PropertyType.SELECT) {
      const options = entityProperty.property.values.map((x: SelectOption) => ({
        id: x.value,
        name: x.label,
      }));
      // @ts-ignore
      return entityProperty.value ? options.filter((x) => entityProperty.value.includes(x.id)).map((x) => {return x.name;}) : "";
    }
    return "";
  }, [entityProperty]);

  useEffect(() => {
    if (entityProperty.property.propertyType===PropertyType.REFERENCE && entityProperty.value) {
      (async () => {

        try {
          const url = entityProperty.property.dataSource === DataSource.datastreamType
            ? `${API_URL}/datastreams/${entityProperty.value}/base`
            : `${API_URL}/containers/${entityProperty.value}/base`
          // @ts-ignore
          const result: SearchResult = await fetchData(url);
          setSelected(result)
        } catch (e) {
        }
      })();
    }
  }, [entityProperty]);

  switch (entityProperty.property.propertyType) {
    case PropertyType.TEXT_FIELD:
    case PropertyType.URL:
    case PropertyType.TEXT_AREA:
      const formattedValue = () => {
        switch (entityProperty.property.textCase) {
          case TextTransform.LOWERCASE:
            return entityProperty.value.toLowerCase();
          case TextTransform.UPPERCASE:
            return entityProperty.value.toUpperCase();
          default:
            return entityProperty.value;
        }
      };
      return (
        <Typography variant={"body2"}>{formattedValue() || "-"}</Typography>
      );
    case PropertyType.SELECT:
      const formatted = Array.isArray(selectPropertyLabel)
        ? selectPropertyLabel.join(", ")
        : selectPropertyLabel;
      return <Typography variant={"body2"}>{formatted || "-"}</Typography>;
    case PropertyType.VOCABULARY:
      const names = entityProperty.value
        .map((uuid: string) => termMap.get(uuid)?.label || "N/A")
        .join(", ");
      return (
        <Typography variant={"body2"}>
          {loading ? "Loading.." : names || "-"}
        </Typography>
      );
    case PropertyType.BOOLEAN:
      return (
        <Typography variant={"body2"}>
          {entityProperty.value ? "Yes" : "No" || "-"}
        </Typography>
      );
    case PropertyType.NUMBER:
      const { requireDecimal, decimalLimit } = entityProperty.property;
      const number = requireDecimal
        ? parseFloat(entityProperty.value).toFixed(decimalLimit)
        : entityProperty.value;
      return <Typography variant={"body2"}>{number || "-"}</Typography>;
    case PropertyType.REFERENCE:
      return (
        <Typography variant={"body2"}>{selected ? selected.label : "-"}</Typography>
      );
    case PropertyType.DATE:
      const mask = entityProperty.property.mask;
      const formattedDate =
        mask && entityProperty.value
          ? moment(entityProperty.value).format(mask)
          : entityProperty.value;
      return <Typography variant={"body2"}>{formattedDate || "-"}</Typography>;
    case PropertyType.RICH_TEXT:
      return entityProperty.value ? (
        <div dangerouslySetInnerHTML={{ __html: entityProperty.value }} />
      ) : (
        <Typography variant={"body2"}>{"-"}</Typography>
      );
    case PropertyType.SPATIAL:
      return (
        <Stack direction={"column"} rowGap={0.5}>
          <Typography variant={"body2"}>
            location: {entityProperty.value.placeName ?? "-"}{" "}
          </Typography>
          <Typography variant={"body2"}>
            latitude: {entityProperty.value.latitude}{" "}
          </Typography>
          <Typography variant={"body2"}>
            longitude: {entityProperty.value.longitude}{" "}
          </Typography>
        </Stack>
      );
    default:
      return null;
  }
};

export default PropertyValue;
