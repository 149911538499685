import * as React from "react";
import {Dispatch, SetStateAction, useCallback, useEffect} from "react";
import {PropertyType} from "../../models/PropertyType";
import {ElementType, FormElement} from "../form/useFormElements";
import {FlatProperty} from "../../models/FlatProperty";
import {getReferenceURL} from "../../components/Properties/ControlledProperty/ReferenceProperty";
import {SelectOption} from "../../models/DataOptions";
import {SpatialValue} from "../../components/Properties/ControlledProperty/SpatialProperty";
import {API_URL} from "../../utils/config";

// Used in previews to initialize the state of the preview component
export const usePreviewDefaultValue = <T>(defaultValue: any, setValue: (value: T) => void, validation: any) => {
  useEffect(() => {
    if (defaultValue === undefined) return
    if (typeof defaultValue === typeof validation) {
      setValue(defaultValue);
    }
  }, [defaultValue, setValue, validation]);
}

// Returns the default value based on the property type
export const useGetInitialDefaultValue = () => {
  return useCallback((type: PropertyType, multipleValues: boolean): string | boolean | string[] | undefined | SpatialValue => {
    switch (type) {
      case PropertyType.BOOLEAN:
        return false;
      case PropertyType.SELECT: {
        if (multipleValues) return [];
        return undefined;
      }
      case PropertyType.VOCABULARY: {
        return []
      }
      case PropertyType.REFERENCE:
        return ""
      case PropertyType.SPATIAL:
        return new SpatialValue()
      default:
        return "";
    }
  }, [])
}

export const getDefaultValueFormElement = (
  flatProperty: FlatProperty,
  formElement: FormElement
): FormElement => {

  const { propertyType, values, multipleValues, minValue, maxValue } = flatProperty

  switch (propertyType) {
    case PropertyType.BOOLEAN: {
      return {
        ...formElement,
        type: ElementType.boolean,
      };
    }
    case PropertyType.NUMBER: {
      return {
        ...formElement,
        type: ElementType.number
      }
    }
    case PropertyType.TEXT_AREA: {
      return {
        ...formElement,
        type: ElementType.textArea
      }
    }
    case PropertyType.SELECT: {
      return {
        ...formElement,
        type: multipleValues ? ElementType.multiSelect : ElementType.select,
        hasNone: true,
        options: values
          .filter((x: SelectOption) => x.value !== undefined && x.value !== "")
          .map((opt: any) => ({
            id: opt.value,
            name: opt.label
          })),
      }
    }
    case PropertyType.REFERENCE: {
      const dataSource = flatProperty.dataSource
      const values = flatProperty.values

      const url = getReferenceURL(values, dataSource)

      return {
        ...formElement,
        type: ElementType.asyncSelect,
        url: url
      }
    }
    case PropertyType.VOCABULARY: {
      const vocID = flatProperty.vocabularyId

      const url = vocID && `${API_URL}/cm/vocabularies/${vocID}/terms/all`

      return {
        ...formElement,
        labelProp: "label",
        valueProp: "uuid",
        type: ElementType.asyncFlatSelect,
        url: url
      }
    }
    case PropertyType.DATE: {
      return {
        ...formElement,
        type: ElementType.date,
        minValue,
        maxValue
      }
    }
    case PropertyType.RICH_TEXT: {
      return {
        ...formElement,
      }
    }
    case PropertyType.SLIDER: {
      return {
        ...formElement,
        type: ElementType.slider,
        multipleValues: flatProperty.multipleValues,
        minValue,
        maxValue
      }
    }
    case PropertyType.AUTO_INCREMENT: {
      return {
        ...formElement,
        columns: 6,
        type: ElementType.number
      }
    }
    default: {
      return {
        ...formElement,
        type: ElementType.text
      }
    }
  }
}

// Updates the default value of the property based on the new selected property type to fix the binding between the state and the preview component
export const usePropertyDefaultValueMiddleware = (handleChange: (e: React.ChangeEvent<any>) => void, setValues: Dispatch<SetStateAction<FlatProperty>>, values: FlatProperty) => {

  const getDefaultValueInitialState = useGetInitialDefaultValue()

  return useCallback((e: React.ChangeEvent<any>) => {

    if (e.target.name === "propertyType") {
      const propertyType = e.target.value
      setValues(prevState => ({
        ...new FlatProperty(),
        name: prevState.name,
        slug: prevState.slug,
        description: prevState.description,
        namespace: prevState.namespace,
        tagName: prevState.tagName,
        propertyGroup: prevState.propertyGroup,
        propertyType: e.target.value,
        defaultValue: getDefaultValueInitialState(propertyType, prevState.multipleValues),
      }))
    } else if (e.target.name === "multipleValues") {
      const multipleValues = e.target.checked
      setValues(prevState => {
        // login to transfer multiple defaults to single default value and vice verca
        let defaultValue = getDefaultValueInitialState(prevState.propertyType, multipleValues)
        const prevDefault = prevState.defaultValue

        if (values.propertyType === PropertyType.SLIDER) {
          const min = (prevState.minValue as number) ?? 0;
          const max = (prevState.maxValue as number) ?? 100;
          return {
            ...prevState,
            multipleValues: e.target.value,
            defaultValue: multipleValues ? [min, max] : [min]
          }
        }

        if (multipleValues) {
          if (prevState.defaultValue && typeof prevDefault === "string") {
            defaultValue = [prevDefault]
          }
        } else {
          if (Array.isArray(prevDefault) && typeof prevDefault[0] === "string") {
            defaultValue = prevDefault[0]
          }
        }

        return {
          ...prevState,
          multipleValues: e.target.value,
          defaultValue
        }
      })
    } else if (e.target.name === "dataSource") {
      setValues(prevState => {
        return {
          ...prevState,
          multipleValues: e.target.value,
          values: [],
          defaultValue: ""
        }
      })
    } else if (e.target.name === "values" && values.propertyType === PropertyType.REFERENCE) {
      setValues(prevState => {
        return {
          ...prevState,
          values: e.target.value,
          defaultValue: ""
        }
      })
    } else if (e.target.name === "vocabularyId") {
      setValues(prevState => {
        return {
          ...prevState,
          defaultValue: []
        }
      })
    }
    handleChange(e)
  }, [getDefaultValueInitialState, handleChange, setValues, values.propertyType])
}
