import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchData } from "../../../utils/utils";
import Paging from "../../../models/Pageable";
import {useState} from "react";
import FormControl from "@mui/material/FormControl";

export interface AsyncOption {
  uuid: string;
  label: string;
}

type AsyncSelectProps = {
  url?: string;
  label: string;
  required?: boolean;
  helperText?: string;
  error?: boolean;
  fullWidth?: boolean;
  name: string;
  size: "small" | "medium";
  value?: string[];
  onChange?: (s: AsyncOption[]) => void;
  disabled?: boolean;
  defaultOptions?: any[];
};

export default function MultiAsyncSelect(props: AsyncSelectProps) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly AsyncOption[]>([]);
  const [query, setQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState<AsyncOption[]>([]);
  const [loading, setLoading] = useState(false)

  const {
    url,
    label,
    required,
    onChange,
    error,
    fullWidth,
    helperText,
    name,
    size,
    // value,
    disabled,
    defaultOptions
  } = props;

  React.useEffect(() => {
    if (!url) {
      if (defaultOptions) setOptions(defaultOptions)
      return
    }

    (async () => {
      setLoading(true)
      try {
        const result: Paging<AsyncOption> = await fetchData(`${url}${query}`);
        setOptions([...result.content]);
      } catch (e) { }

      setLoading(false)

    })();

  }, [url, query, open, defaultOptions]);

  // useEffect(() => {
  //   if (value && options.length > 0) {
  //     const option = options.find(x => x.uuid === value)
  //     setSelectedValue(option ?? null)
  //     if (option) {
  //       setQuery(option.label)
  //     }
  //   }
  // }, [options, options.length, value])

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);


  return (
    <FormControl size={"small"} fullWidth={fullWidth}>
      {/*<InputLabel id={`${name}-select-label`}>{label}</InputLabel>*/}
      <Autocomplete
        multiple
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        id={name}
        fullWidth={fullWidth}
        value={selectedValue}
        onChange={(event, newValue) => {
          setSelectedValue([]);
          if (onChange) {
            onChange(newValue);
            setQuery("")
          }
        }}
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
        getOptionLabel={(option) => option.label}
        options={options}
        loading={loading}
        disabled={disabled}
        filterOptions={(options) => options}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            size={size}
            helperText={helperText}
            error={error}
            required={required}
            label={label}
            fullWidth={fullWidth}
            onChange={(e) => setQuery(e.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}
