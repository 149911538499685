import { useMemo } from "react";
import {
  ElementType,
  FormElement,
} from "../../../../hooks/form/useFormElements";
import { useContainerTypeList } from "../../../../store/containerTypeStore";
import { useArrayOptions } from "../../../../hooks/common/useArrayOptions";
import ContainerType from "../../../../models/ContainerType";
import DatastreamType from "../../../../models/DatastreamType";
import {useDatastreamTypeList} from "../../../../store/datastreamTypeStore";

export const useAdvancedOptionElements = (): FormElement[] => {
  const [containerTypes] = useContainerTypeList();
  const containerTypeOptions = useArrayOptions<ContainerType>(
    containerTypes,
    "uuid",
    "name"
  );
  const [datastreamTypes] = useDatastreamTypeList();
  const datastreamTypeOptions = useArrayOptions<DatastreamType>(
    datastreamTypes,
    "uuid",
    "name"
  );

  return useMemo(() => {
    return [
      {
        id: "containerType",
        label: "formElement.containerType",
        type: ElementType.select,
        isRequired: false,
        hasNone: true,
        options: containerTypeOptions,
        columns: 6,
      },
      {
        id: "datastreamType",
        label: "formElement.datastreamType",
        type: ElementType.select,
        isRequired: false,
        hasNone: true,
        options: datastreamTypeOptions,
        columns: 6,
      },
    ];
  }, [containerTypeOptions, datastreamTypeOptions]);
};
