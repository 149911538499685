import {
  Dialog as MuiDialog
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import LinearProgress from "@mui/material/LinearProgress";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import React, {ReactNode} from "react";
import { Breakpoint } from "@mui/system";
import Button from "@mui/material/Button";

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  primaryAction?: () => void;
  secondaryAction?: () => void;
  primaryActionTitle?: string;
  secondaryActionTitle?: string;
  title: string;
  message: string | ReactNode;
  maxWidth?: Breakpoint | false;
  fullWidth?: boolean;
  inProgress?: boolean;
  children?: any
};

function ConfirmationDialog({
  handleClose,
  open,
  title,
  message,
  primaryActionTitle = "Confirm",
  secondaryActionTitle = "Cancel",
  primaryAction,
  secondaryAction,
  maxWidth = "xs",
  fullWidth,
  inProgress,
  children
}: DialogProps) {

  const handleSecondaryAction = () => {
      if (secondaryAction) {
        secondaryAction();
      } else {
        handleClose();
      }
  }

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      {inProgress && <LinearProgress style={{ marginBottom: -4 }} />}
      <DialogTitle id="alert-dialog-title">
        <Typography variant={"body1"} color={"error.main"}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant={"body1"}>{message}</Typography>
        {children && children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSecondaryAction}>{secondaryActionTitle}</Button>
        {primaryAction && (
          <Button onClick={primaryAction} disabled={inProgress} autoFocus>
            {primaryActionTitle}
          </Button>
        )}
      </DialogActions>
    </MuiDialog>
  );
}

export default ConfirmationDialog;
