import { MediaAsset } from "../../../models/MediaAsset";
import CardActions from "@mui/material/CardActions";
import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import * as React from "react";
import moment from "moment";
import DefaultCover from "../../../assets/images/default_image.png";
import { Download, Edit } from "@mui/icons-material";
import useImageDownload from "../../../extensions/guide/hooks/useImageDownload";

type MediaCardProps = {
  asset: MediaAsset;
  checked?: boolean;
  onCheck?: (uuid: string) => void;
  checkable?: boolean;
  onEdit?: (asset: MediaAsset) => void;
};

function MediaCard(props: MediaCardProps) {
  const { asset, onCheck, checked, checkable, onEdit } = props;

  const thumb = asset.bitstreamFile.thumbnails.mediumUrl || DefaultCover;

  const [downloadImage, isDownloading] = useImageDownload(asset)

  return (
    <Card
      onClick={() => {
        if (onCheck && checkable) {
          onCheck(asset.uuid);
        }
      }}
      sx={{
        cursor: checkable ? "pointer" : "inherit",
        borderColor: checked ? "primary.main" : "divider",
        transition: "all 0.3s",
      }}
    >
      <img
        src={thumb}
        alt={asset.altText}
        loading="lazy"
        style={{
          width: "100%",
          height: 160,
          objectFit: "cover",
        }}
      />
      {checkable && onCheck && (
        <Checkbox
          sx={{
            backgroundColor: "background.paper",
            color: "primary",
            position: "absolute",
            left: 0,
            m: 1,
            top: 0,
            "&:hover": {
              backgroundColor: "background.neutral",
            },
          }}
          checked={checked}
          size={"small"}
        />
      )}
      <Divider />
      <CardActions>
        <Stack rowGap={0.5} overflow={"hidden"} flex={1}>
          <Stack direction={"row"} alignItems={"center"} flex={1}>
            <Typography noWrap flex={1} sx={{ mr: 1 }}>
              {asset.label}
            </Typography>
            <IconButton
              size={"small"}
              disabled={isDownloading}
              onClick={(e) => {
                e.stopPropagation();
                downloadImage()
              }}
            >
              {isDownloading ? <CircularProgress size={16}/> : <Download fontSize={"small"} />}
            </IconButton>
            {onEdit && (
              <IconButton
                size={"small"}
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(asset);
                }}
              >
                <Edit fontSize={"small"} />
              </IconButton>
            )}
          </Stack>
          <Typography variant={"caption"} color={"textSecondary"}>
            {moment.utc(asset.createdAt).local().format("LLL")}
          </Typography>
        </Stack>
      </CardActions>
    </Card>
  );
}

// useDatastreamFileImage
export default MediaCard;
