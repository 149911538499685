import * as React from "react";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchData } from "../../utils/utils";
import Paging from "../../models/Pageable";
import { FormControl } from "@mui/material";
import axios from "axios";
import Typography from "@mui/material/Typography";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListSubheader from "@mui/material/ListSubheader";
import Popper from "@mui/material/Popper";
import { styled, useTheme } from "@mui/material/styles";


interface LoadMoreFunctionType {
  (index: number): void;
}

// Context definition
const LoadMoreContext = React.createContext<LoadMoreFunctionType>(() => {});


const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    },
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);
  const loadMore = useContext(LoadMoreContext)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          onItemsRendered={props => {
            loadMore(props.visibleStopIndex)
          }}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export interface AsyncOption {
  uuid: string;
  label: string;
}

type AsyncSelectProps = {
  url?: string;
  label: string;
  required?: boolean;
  recommended?: boolean;
  helperText?: string;
  error?: boolean;
  fullWidth?: boolean;
  name: string;
  size: "small" | "medium";
  variant?: "standard" | "outlined" | "filled"
  value?: string;
  onChange?: (s: string) => void;
  disabled?: boolean;
  defaultOptions?: any[];
  formatOptions?: (options: any) => AsyncOption[]
};

export default function AsyncSelect(props: AsyncSelectProps) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly AsyncOption[]>([]);
  const [query, setQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState<AsyncOption | null>(null);
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState<Paging<AsyncOption>>(new Paging())

  const {
    url,
    label,
    required,
    recommended,
    onChange,
    error,
    fullWidth,
    helperText,
    name,
    size,
    value,
    disabled,
    defaultOptions,
    formatOptions,
    variant = "standard"
  } = props;

  const cancel = useRef(undefined)

  React.useEffect(() => {
    if (!url) {
      if (defaultOptions) setOptions(defaultOptions)
      return
    }

    (async () => {

      if (!open && defaultOptions) return
      if (cancel.current) {
        // @ts-ignore
        cancel.current();
      }

      const config = {
        cancelToken: new axios.CancelToken(function executor(c: any) {
          // @ts-ignore
          cancel.current = c; // keep reference for cancellation
        }),
      };

      setLoading(true)
      try {
        // @ts-ignore
        const result: Paging<AsyncOption> = await fetchData(`${url}${query}`, config);
        setResults(result)
        setOptions([...result.content]);
      } catch (e) { }

      setLoading(false)

    })();

  }, [url, query, open, defaultOptions]);

  const handleLoadMore = async (index: number) => {
    const isLastItem = options.length -1 === index
    if (loading || results.last || !isLastItem) return

    setLoading(true)

    try {
      const result: Paging<AsyncOption> = await fetchData(`${url}${query}&page=${results.number + 1}`);
      setOptions(prevState => [...prevState, ...result.content]);
      setResults(result)
    } catch (e) { }

    setLoading(false)
  }

  const formatted = useMemo(() => {
    return formatOptions ? formatOptions(options) : options
  }, [options, formatOptions])

  useEffect(() => {
    if (value && defaultOptions && defaultOptions.length > 0) {
      const option = defaultOptions.find(x => x.uuid === value)
      setSelectedValue(option ?? null)
      if (option) {
        setQuery(option.label)
      }
    } else if (!defaultOptions) {
      const opt = formatted.find(x => x.uuid === value)
      setSelectedValue(opt ?? null)
    }
  }, [defaultOptions, options, options.length, value, formatted])

  useEffect(() => {
    setQuery("")
  }, [value])

  useEffect(() => {
    if (!open && !value) {
      setSelectedValue(null)
    }
  }, [open, value])

  React.useEffect(() => {
    if (!open && defaultOptions) {
      setOptions([]);
    }
  }, [open, defaultOptions]);


  return (
    <LoadMoreContext.Provider value={handleLoadMore}>
      <FormControl size={"small"} fullWidth={fullWidth}>
        {/*<InputLabel id={`${name}-select-label`}>{label}</InputLabel>*/}
        <Autocomplete
          PopperComponent={StyledPopper}
          ListboxComponent={ListboxComponent}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          disableListWrap
          id={name}
          fullWidth={fullWidth}
          value={selectedValue}
          onChange={(event, newValue) => {
            setSelectedValue(null);
            if (onChange) {
              onChange(newValue?.uuid ?? "");
              setQuery("")
            }
          }}
          isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
          options={formatted}
          loading={loading}
          disabled={disabled}
          filterOptions={(formatted) => formatted}
          renderOption={(props, option, state) =>
            [props, option.label, state.index] as React.ReactNode
          }
          renderInput={(params) => (
            <TextField
              {...params}
              name={name}
              size={size}
              helperText={helperText}
              error={error}
              required={required}
              variant={variant}
              label={<>{label} {recommended && <sup>(Rec)</sup>}</>}
              fullWidth={fullWidth}
              onChange={(e) => setQuery(e.target.value)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </FormControl>
    </LoadMoreContext.Provider>
  );
}
