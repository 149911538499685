class ContainerReport {
  label: string="";
  containerCount:number=0;
  datastreamCount:number=0;
  totalStorageSpace:number=0;
  totalStorageSpaceHR:string="";

}


export default ContainerReport
